export function arraysEquals<T>(one: Array<T>, two: Array<T>): boolean {
  if (one.length !== two.length) {
    return false;
  }
  return one.map((x, i) => two[i] === x).every((b) => b === true);
}

export function removeWhere<T>(arr: Array<T>, removePredicate: (elem: T) => boolean): Array<T> {
  return arr.filter((x) => !removePredicate(x));
}
