import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import counterReducer from "../features/counter/counterSlice";
import articleReducer from "../features/course/articleSlice";
import courseReducer from "../features/course/courseSlice";
import quizReducer from "../features/quiz/quiz-slice";
import appReducer from "../features/app/appSlice";

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    course: courseReducer,
    article: articleReducer,
    quiz: quizReducer,
    app: appReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppStore = typeof store;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
