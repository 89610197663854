import { Link } from "react-router-dom";
import styled from "styled-components";
import { useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { quizesForArticle } from "../../features/quiz/quiz-slice";
import { ThemeType } from "../../themes";

const StyledQuizButton = styled(Link)`
  padding: 0.75rem 1.5rem;
  color: ${(props: ThemeType) => props.theme.button.color};
  background: ${(props: ThemeType) => props.theme.button.bg};
  border-radius: 0.5rem;
  text-decoration: none;
  margin-right: 1rem;

  &:hover {
    color: ${(props: ThemeType) => props.theme.button.hover?.color};
    background: ${(props: ThemeType) => props.theme.button.hover?.bg};
  }
`;

const QuizButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding-top: 2rem;
  padding-bottom: 2rem;
  border-top: 1px solid rgb(220, 220, 220);
  border-bottom: 1px solid rgb(220, 220, 220);
`;

function quizIdsForHeader(state: RootState, articleId: string, header: string): string[] {
  let quizes = quizesForArticle(state, articleId);
  let quizesForHeader = quizes.filter((q) => q.forHeader === header);
  return quizesForHeader.map((q) => q.id);
}

type QuizButtonProps = { articleId: string; header: string };

export const QuizButton = (props: QuizButtonProps) => {
  let { header, articleId } = props;

  let quizes = useAppSelector((state: RootState) => quizesForArticle(state, articleId));
  let courseId = useAppSelector((state) => state.course.id);
  let quizIds = useAppSelector((state) => quizIdsForHeader(state, articleId, header));

  function getQuestionsCount(quizId: string): number {
    let quiz = quizes.find((q) => q.id === quizId);
    if (quiz === undefined) {
      return 0;
    }
    return quiz.questions.length;
  }

  if (quizIds.length === 0) {
    return <></>;
  }

  return (
    <QuizButtonContainer>
      {quizIds.map((id) => (
        <StyledQuizButton key={`quiz_button_for_${id}`} to={`/quizpage/${courseId}/${id}`}>
          Реши тест из {getQuestionsCount(id)} вопросов
        </StyledQuizButton>
      ))}
      {/* <StyledQuizButton to={`/quizpage/${courseId}/${quizId}`}>
        Реши тест из {getQuestionsCount(quizId)} вопросов
      </StyledQuizButton> */}
    </QuizButtonContainer>
  );
};
