import quizIcon from "./Quiz.svg";
import tableOfContents from "./Table-of-contents.svg";
import courseIcon from "./Course.svg";
import progressBlack from "./Progress-black.svg";
import { ThemeColorScheme } from "../../../themes";

export {
    progressBlack,
    quizIcon,
    tableOfContents,
    courseIcon
}

export function getIcon(scheme: ThemeColorScheme, fileName: string) {
    return require('./' + scheme + '/' + fileName);
}