import prevIcon from "./icons/Prev.svg";
import nextIcon from "./icons/Next.svg";
import styled from "styled-components";

export type SlideNavPanelProps = {
  onNextButtonClick?: () => void;
  onPrevButtonClick?: () => void;
};

const NavButton = styled.button`
  border: none;
  background: transparent;
  padding: 0;

  padding: 0.1rem;
`;

const NavContainer = styled.div`
  display: flex;
  margin-left: auto;
  padding-left: 1rem;
`;

export default function SlideNavPanel(props: SlideNavPanelProps) {
  let { onNextButtonClick, onPrevButtonClick } = props;
  const imgWidth = 32;

  return (
    <NavContainer>
      {onPrevButtonClick !== undefined && (
        <NavButton onClick={onPrevButtonClick}>
          <img width={`${imgWidth}px`} src={prevIcon} alt="" />
        </NavButton>
      )}
      {onNextButtonClick && (
        <NavButton onClick={onNextButtonClick}>
          <img width={`${imgWidth}px`} src={nextIcon} alt="" />
        </NavButton>
      )}
    </NavContainer>
  );
}
