import { createSlice, createAsyncThunk, createEntityAdapter, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { getCourseFromApi, Article, Course } from "../datacontext/dbcontext";
import { selectAreAllQuizesChecked } from "../quiz/quiz-slice";

type LoadingStatus = "idle" | "loading" | "succeeded" | "failed";

interface CourseState {
  status: LoadingStatus;
  currentId?: string;
  error: string;
}

const courseAdapter = createEntityAdapter<Article>({});

const initialState = courseAdapter.getInitialState<CourseState>({
  status: "idle",
  currentId: undefined,
  error: "",
});

export const fetchCourse = createAsyncThunk("course/fetchCourse", async (courseId: string) => {
  const response = await getCourseFromApi(courseId);
  return response;
});

const articleSlice = createSlice({
  name: "article",
  initialState: initialState,
  reducers: {   
  },
  extraReducers(builder) {
    builder
      .addCase(fetchCourse.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCourse.fulfilled, (state, action: PayloadAction<Course>) => {
        let course = action.payload;
        courseAdapter.upsertMany(state, course.articles);
        if (course.articles.length > 0) {
          state.currentId = course.articles[0].id;
        }
        state.status = "succeeded";
      })
      .addCase(fetchCourse.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const { selectAll: selectAllArticles, selectById: findArticle } = courseAdapter.getSelectors(
  (state: RootState) => state.article
);

export function selectNextArticle(state: RootState, articleId: string): { id: string; title: string } {
  let allIds = Object.values(state.article.entities).map((a) => a?.id as string);

  let articleForQuizIndex = allIds.indexOf(articleId);
  if (articleForQuizIndex !== allIds.length - 1) {
    let nextId = allIds[articleForQuizIndex + 1];
    return {
      id: nextId,
      title: state.article.entities[nextId]?.title ?? "",
    };
  } else {
    return {
      id: "",
      title: "",
    };
  }
}

export function selectArticlesWithUncheckedQuizes(state: RootState): Article[] {
  let allArticleIds = Object.keys(state.article.entities);
  let allArticles = Object.values(state.article.entities) as Article[];
  let uncheckedArtIds = allArticleIds.filter((artId) => selectAreAllQuizesChecked(state, artId) === false);
  //don't include current article
  if (state.article.currentId !== undefined) {
    uncheckedArtIds = uncheckedArtIds.filter((id) => id != state.article.currentId);
  }

  return allArticles.filter((art) => uncheckedArtIds.indexOf(art?.id) !== -1);
}

export default articleSlice.reducer;
