import { Link } from "react-router-dom";
import styled from "styled-components";
import { useAppSelector } from "../../app/hooks";
import { Quiz } from "../../features/datacontext/dbcontext";
import { ThemeType } from "../../themes";

type QuizCardProps = {
  quiz: Quiz;
};

const StyledQuizCard = styled.div` 
  border-radius: 0.5rem;
  border: ${(props: ThemeType) => props.theme.quizCard.border};
  box-shadow: ${(props: ThemeType) => props.theme.quizCard.shadow};
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    margin-bottom: 1rem;
  }
`;

const QuizTitle = styled.p`
  margin-bottom: 0.5rem;
`;

const QuizImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 0.5rem 0.5rem 0 0;
`;

const InfoPanel = styled.div`
  padding: 1rem;

  p {
    margin: 0;
  }
`;

const ShortDescription = styled.div`
  font-size: 0.8rem;
  color: rgb(100, 100, 100);
`;

const StartButtonContainer = styled.div`
  display: flex;
  justify-content: flex-center;
  margin-top: 1rem;
`;

const StartQuizButton = styled(Link)<ThemeType>`
  font-size: 0.8rem;
  padding: 0.25rem 0.7rem;
  color: ${(props: ThemeType) => props.theme.button.color};
  border-radius: 0.25rem;
  background-color: ${(props: ThemeType) => props.theme.button.bg};
  text-decoration: none; 

  &:hover {
    background-color: ${(props: ThemeType) => props.theme.button.hover?.bg};
    color: ${(props: ThemeType) => props.theme.button.hover?.color};
  }
`;

export default function QuizCard(props: QuizCardProps) {
  let courseId = useAppSelector((state) => state.course.id);
  let { quiz } = props;

  return (
    <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 col-xxl-2">
      <StyledQuizCard>
        <QuizImage src={quiz.imageSrc} alt="" className="quiz-image" />
        <InfoPanel>
          <QuizTitle>{quiz.title}</QuizTitle>
          <ShortDescription>{quiz.shortDescription}</ShortDescription>
          <StartButtonContainer>
            <StartQuizButton to={`/quizpage/${courseId}/${quiz.id}`}>Старт</StartQuizButton>
          </StartButtonContainer>
        </InfoPanel>
      </StyledQuizCard>
    </div>
  );
}
