import { CheckResultEntry, SelectedOption } from "../quiz/quiz-slice";

export interface ArticleTag {
  tagName: "h" | "p" | "li" | "img" | "unknown";
  content: string;
  imageSrc: string;
  imageAlt: string;
  imageCaption: string;
  contentAsParts: Array<ContentPart>;
}

export interface ContentPart {
  text: string;
  isBold: boolean;
  isItalic: boolean;
  isEmoji: boolean;
  isFormula: boolean;
}

export interface Article {
  id: string;
  title: string;
  shortDescription: string;
  text: string;
  imageSrc?: string;
  tags: ArticleTag[];
}

export interface AnswerOption {
  text: string;
  isCorrect: boolean;
}

export interface Question {
  text: string;
  textContent: ArticleTag[];
  options: AnswerOption[];
}

export interface Quiz {
  id: string;
  title: string;
  forArticleId: string;
  forHeader: string;
  shortDescription: string;
  imageSrc?: string;
  questions: Question[];
}

export interface Course {
  id: string;
  title: string;
  shortDescription: string;
  imageSrc?: string;
  articles: Article[];
  quizes: Quiz[];
}

export type CourseSummary = {
  id: string;
  title: string;
  shortDescription: string;
};

export async function getCourseFromApi(courseId: string): Promise<Course> {
  const response = await fetch(`/course/get/${courseId}`);
  const data = await response.json();
  saveCourse(data);
  console.log(data);
  return data;
}

export async function getCourseSummaryFromApi(courseId: string): Promise<CourseSummary> {
  const response = await fetch(`/course/GetShortDescription/${courseId}`);
  const data = await response.json();
  return data;
}

export function saveProgress(selectedOptions: SelectedOption[], checkResults: CheckResultEntry[]) {
  let locStorage = window.localStorage;
  let data = JSON.parse(locStorage.getItem("how-to-app") ?? "{}");
  let progress = {
    selectedOptions,
    checkResults,
  };
  locStorage.setItem(
    "how-to-app",
    JSON.stringify({
      ...data,
      ...progress,
    })
  );
}

export function saveCourse(course: Course) {
  let locStorage = window.localStorage;
  let data = JSON.parse(locStorage.getItem("how-to-app") ?? "{}");
  delete data["course"];
  data["course"] = {
    id: course.id,
  };
  locStorage.setItem("how-to-app", JSON.stringify(data));
}

export type CourseProgress = {
  selectedOptions: SelectedOption[];
  checkResults: CheckResultEntry[];
};

export function loadProgress(): CourseProgress {
  let locStorage = window.localStorage;
  let data = JSON.parse(locStorage.getItem("how-to-app") ?? "{}");
  let selectedOptions = new Array<SelectedOption>();
  let checkResults = new Array<CheckResultEntry>();

  if (Object.keys(data).indexOf("selectedOptions") !== -1) {
    selectedOptions = data["selectedOptions"];
  }
  if (Object.keys(data).indexOf("checkResults") !== -1) {
    checkResults = data["checkResults"];
  }
  return {
    selectedOptions,
    checkResults,
  };
}
