import { ReactNode } from "react";
import { ThemeProvider } from "styled-components";
import { useAppSelector } from "../../app/hooks";
import { getColorSchemeObject } from "../../themes";

type ThemeWrapperProps = {
  children: ReactNode;
};

export default function ThemeWrapper(props: ThemeWrapperProps) {
  let { children } = props;

  let colorScheme = useAppSelector((state) => state.app.colorScheme);

  

  return <ThemeProvider theme={getColorSchemeObject(colorScheme)}>{children}</ThemeProvider>;
}
