import { ReactNode } from "react";
import Modal from "react-modal";
import styled from "styled-components";
import { useAppSelector } from "../../app/hooks";
import { getColorSchemeObject, ThemeType } from "../../themes";
import closeIcon from "./icons/Close.svg";
import closeWhiteIcon from "./icons/CloseWhite.svg";

export type NeonModalProps = {
  modalIsOpen: boolean;
  closeModal: () => void;
  children?: ReactNode;
  title?: string;
};

export const NeonModal = styled(Modal)<ThemeType>`
  background: ${(props: ThemeType) => props.theme.modal.bg};
  color: ${(props: ThemeType) => props.theme.modal.color};
  position: absolute;
  border: ${(props: ThemeType) => props.theme.modal.borderStyle};
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
  border-radius: 0.5rem;
  box-shadow: ${(props: ThemeType) => props.theme.modal.shadow};
  inset: 5%;

  @media (max-width: 768px) {
    inset: 0;
    border: none;
    border-radius: 0;
  }

  .modal-header {
    display: flex;
    align-items: center;
    padding-bottom: 0.5rem;

    .modal-title {
      color: ${(props: ThemeType) => props.theme.modal.color};
    }
  }

  .modal-title {
    font-size: 1.5rem;
    font-weight: bold;
  }

  .close-btn {
    margin-left: auto;
    background-color: transparent;
    border: none;
    border-radius: 100rem;
    width: 42px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;

    img {
      width: 32px;
      height: 32px;
    }    
  }
`;

export function NeonModalDialog(props: NeonModalProps) {
  let { closeModal, modalIsOpen, children, title } = props;
  let scheme = useAppSelector((state) => getColorSchemeObject(state.app.colorScheme));
  let closeButtonColor = scheme.modal.closeButtonColor;

  return (
    <NeonModal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      shouldCloseOnOverlayClick={true}
      className="app-modal"
      style={{
        overlay: {
          position: "fixed",
          inset: "0px",
          backgroundColor: scheme.modal.overlayBg,
          zIndex: "10000",
        },
      }}
    >
      <div className="modal-header">
        <span className="modal-title">{title}</span>
        <button className="close-btn" onClick={closeModal}>
          {closeButtonColor === "black" && <img src={closeIcon} alt="close" />}
          {closeButtonColor === "white" && <img src={closeWhiteIcon} alt="close" />}
        </button>
      </div>
      <div className="modal-content">{children}</div>
    </NeonModal>
  );
}
