import styled from "styled-components";
import { ArticleTag, ContentPart } from "../../features/datacontext/dbcontext";
import { MathJax, MathJaxContext } from "better-react-mathjax";
import { emojiByName } from './icons/index';

const SlideImage = styled.img`
  width: 100%;
  border-radius: 0.5rem;
`;

const StyledListItem = styled.li`
  list-style-type: disc;
  padding-left: 1rem;
  padding-bottom: 0.5rem;
  font-size: 1.2rem;
`;

const StyledParagraph = styled.p`
  font-size: 1.2rem;
`;

const StyledSmile = styled.img`
  width: 24px;
`;

function Emoji(props: { emojiName: string }) {
  let { emojiName } = props;
  let imojiImg = emojiByName(emojiName.replaceAll(":", ""));
  return <StyledSmile src={imojiImg} />
}

const config = {
  loader: { load: ["[tex]/html"] },
  tex: {
    packages: { "[+]": ["html"] },
    inlineMath: [
      ["$", "$"],
      ["\\(", "\\)"],
    ],
    displayMath: [
      ["$$", "$$"],
      ["\\[", "\\]"],
    ],
  },
};


function ContentPartArray(props: { paragraphKey: string; parts: ContentPart[] }) {
  let { parts, paragraphKey } = props;

  return (
    <>
      {parts.map((c, partIndex) => {
        let partKey = `${paragraphKey}_part_${partIndex}`;
        if (c.isBold) {
          if (c.isEmoji) {
            return <Emoji key={partKey} emojiName={c.text} />;
          } else if (c.isFormula) {
            return (
              <MathJaxContext key={partKey} config={config}>
                <MathJax inline>{"$" + c.text + "$"}</MathJax>
              </MathJaxContext>
            );
          } else {
            return <strong key={partKey}>{c.text}</strong>;
          }
        } else if (c.isEmoji) {
          return <Emoji key={partKey} emojiName={c.text} />;
        } else if (c.isItalic) {
          return <i key={partKey}>{c.text}</i>;
        } else if (c.isFormula) {
          return (
            <MathJaxContext key={partKey} config={config}>
              <MathJax inline>{"$" + c.text + "$"}</MathJax>
            </MathJaxContext>
          );
        } else {
          return <span key={partKey}>{c.text}</span>;
        }
      })}
    </>
  );
}

function TextContent(props: { tags: Array<ArticleTag> }) {
  let { tags } = props;
  return (
    <>
      {tags.map((t, para_index) => {
        switch (t.tagName) {
          case "h":
            return <h2 key={para_index}>{t.content}</h2>;
          case "p":
            return (
              <StyledParagraph key={para_index}>
                <ContentPartArray paragraphKey={para_index.toString()} parts={t.contentAsParts} />
              </StyledParagraph>
            );
          case "li":
            return (
              <StyledListItem key={para_index}>
                <ContentPartArray paragraphKey={para_index.toString()} parts={t.contentAsParts} />
              </StyledListItem>
            );
          default:
            return <p key={para_index}>{t.content}</p>;
        }
      })}
    </>
  );
}

function GraphicContent(props: { tags: Array<ArticleTag> }) {
  return (
    <>
      {props.tags.map((t, i) => (
        <div key={`img_container_${i}`}>
          <SlideImage key={`img_${i}`} src={t.imageSrc} alt={t.imageAlt}></SlideImage>
          <p className="text-center" key={`img_caption_${i}`}>
            {t.imageCaption}
          </p>
        </div>
      ))}
    </>
  );
}

export function GraphicsLayout(props: { textTags: Array<ArticleTag>; graphicTags: Array<ArticleTag> }) {
  let { textTags, graphicTags } = props;

  return (
    <>
      <div className="col-12 col-md-5">
        <GraphicContent tags={graphicTags} />
      </div>
      <div className="col-12 col-md-7">
        <TextContent tags={textTags} />
      </div>
    </>
  );
}

export function TextLayout(props: { textTags: Array<ArticleTag> }) {
  let { textTags } = props;

  return (
    <div className="col-12">
      <TextContent tags={textTags} />
    </div>
  );
}
