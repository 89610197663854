import styled from "styled-components";
import { useAppSelector } from "../../app/hooks";
import { selectPercentageOfCompletion } from "../../features/quiz/quiz-slice";

const StyledProgressBar = styled.div`
  background-color: #006aaa;

  span {
    color: white;
    font-size: 0.8rem;
  }
`;

export default function CompletionProgressBar(props: { quizId: string }) {
  let { quizId } = props;
  let comp = useAppSelector((state) => {
    let completion = selectPercentageOfCompletion(state, quizId);
    return `${Math.round(completion.completionPct)}%`;
  });

  return (
    <div className="progress mb-1" style={{ height: "25px", width: "100%" }}>
      <StyledProgressBar className="progress-bar" role="progressbar" style={{ width: comp }}>
        <span>{comp}</span>
      </StyledProgressBar>
    </div>
  );
}
