import { useParams } from "react-router-dom";
import ViewArticle from "../../Components/ViewArticle";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { useEffect } from "react";
import Panel from "../../Components/Common/Panel";
import Spinner from "../../Components/Spinner/Spinner";
import { initApp } from "../../features/app/app-initializer";

export default function CourseRoute() {
  let params = useParams();
  let dispatch = useAppDispatch();
  let courseId = params.id !== undefined ? params.id : "";
  let articleId = params.articleId !== undefined ? params.articleId : "";
  let courseIsFetched = useAppSelector((state: RootState) => state.article.ids.length > 0);
  let isLoadingInitial = useAppSelector((state) => state.app.isLoadInitial);
  let isLoading = useAppSelector((state) => state.app.status === "loading");

  useEffect(() => {
    initApp(dispatch, courseId, isLoadingInitial);
  }, [dispatch, courseId, articleId, courseIsFetched, isLoadingInitial]);

  if (isLoadingInitial) {
    return <>{isLoading && <Spinner />}</>;
  }

  return (
    <Panel padding="1.5rem">
      {courseIsFetched ? <ViewArticle courseId={courseId} articleId={articleId} /> : <p>Контент загружается...</p>}
    </Panel>
  );
}
