import { AppNavMenu } from "../AppNavMenu/AppNavMenu";
import Logo from "../Logo";
import SettingsButton from "./SettingsButton";

const LeftNavbar = () => {
  return (
    <>
      <Logo />
      <AppNavMenu />
      <SettingsButton />
    </>
  );
};

export default LeftNavbar;
