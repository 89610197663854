import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import CourseInfo from "../../Components/CourseInfo/CourseInfo";
import Spinner from "../../Components/Spinner/Spinner";
import { initApp } from "../../features/app/app-initializer";

export default function CourseInfoPage() {
  let dispatch = useAppDispatch();
  let params = useParams();
  let courseId = params.id !== undefined ? params.id : "";
  let isLoadingInitial = useAppSelector((state) => state.app.isLoadInitial);
  let isLoading = useAppSelector((state) => state.app.status === "loading");

  useEffect(() => {
    initApp(dispatch, courseId, isLoadingInitial);
  }, [dispatch, courseId, isLoadingInitial]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <CourseInfo courseId={courseId} />
  );
}
