import { AppDispatch } from "../../app/store";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { fetchCourseSummary } from "../course/courseSlice";
import { fetchCourse } from "../course/articleSlice";
import { loadProgress } from "../datacontext/dbcontext";
import { incorporateProgress } from "../quiz/quiz-slice";

export function initApp(dispatch: AppDispatch, courseId: string, isLoadingInitial: boolean) {

  if (isLoadingInitial) {
    dispatch(fetchCourseSummary(courseId));
  }

  if (!isLoadingInitial) {
    console.log("Course has already been fetched");
    return;
  }
  console.log(`Fetching <${courseId}> course`);
  dispatch(fetchCourse(courseId));
  let progress = loadProgress();
  dispatch(incorporateProgress(progress));
}
