import styled from "styled-components";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import {
  selectQuestions,
  selectCurrentQuestionIndex,
  displayQuestion,
  selectIsQuizComplete,
  displayResults,
} from "../../features/quiz/quiz-slice";
import { ThemeType as AppTheme } from "../../themes";

const NavContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 0.5rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 0.2rem;
`;

type NavButtonStatus = {
  active?: boolean;
};

type NavButtonProps = NavButtonStatus & AppTheme;

const NavButton = styled.button<NavButtonProps>`
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  background-color: ${(props: NavButtonProps) =>
    props.active ? props.theme.quizNavBtn.active?.bg : props.theme.quizNavBtn.bg};
  color: ${(props: NavButtonProps) =>
    props.active ? props.theme.quizNavBtn.active?.color : props.theme.quizNavBtn.color};
`;

type IndicatorStatus = {
  status: "red" | "green" | "invisible";
};

const Indicator = styled.div<IndicatorStatus & AppTheme>`
  margin-top: 2px;
  height: 5px;
  border-radius: 0.25rem;
  background-color: ${(props: IndicatorStatus & AppTheme) => {
    switch (props.status) {
      case "green":
        return props.theme.correctnessInd.correct;
      case "red":
        return props.theme.correctnessInd.incorrect;
      case "invisible":
        return "inherit";
    }
  }};
`;

export default function QuizNavigation() {
  let dispatch = useAppDispatch();
  let questions = useAppSelector(selectQuestions);
  let currIndex = useAppSelector(selectCurrentQuestionIndex);
  let correctStatus = useAppSelector((state) => {
    let currentQuizId = state.quiz.currentId ?? "";
    let existedChecks = state.quiz.checkResults.filter((r) => r.quizId === currentQuizId);
    let res = questions.map((_, index) => existedChecks.find((c) => c.questionIndex === index)?.isCorrect ?? undefined);
    return res;
  });
  let isComplete = useAppSelector(selectIsQuizComplete);
  let resultsDisplayed = useAppSelector((state) => state.quiz.resultsDisplayed);

  function isQuestionActive(qIndex: number): boolean {
    if (resultsDisplayed) {
      return false; // is results are displayed then don't display active question
    }
    return currIndex === qIndex;
  }

  function getIndicatorStatus(qIndex: number): IndicatorStatus {
    switch (correctStatus[qIndex]) {
      case true:
        return {
          status: "green",
        };
      case false:
        return {
          status: "red",
        };
      default:
        return { status: "invisible" };
    }
  }

  return (
    <NavContainer>
      {questions.map((_, i) => (
        <ButtonContainer key={i}>
          <NavButton
            active={isQuestionActive(i)}
            onClick={() => {
              dispatch(displayQuestion(i));
            }}
          >
            {i + 1}
          </NavButton>
          <Indicator status={getIndicatorStatus(i).status} />
        </ButtonContainer>
      ))}
      {isComplete && (
        <ButtonContainer>
          <NavButton
            onClick={() => {
              dispatch(displayResults(true));
            }}
            active={resultsDisplayed}
          >
            Итоги
          </NavButton>
        </ButtonContainer>
      )}
    </NavContainer>
  );
}
