import { createSlice, createEntityAdapter, PayloadAction, EntityState } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { arraysEquals } from "../../Utils/arrays";
import { fetchCourse } from "../course/articleSlice";
import { CourseProgress, Question, Quiz, saveProgress } from "../datacontext/dbcontext";
import * as utils from "./utils";

const quizAdapter = createEntityAdapter<Quiz>({});

type CurrentQuiz = {
  id: string;
  questions: Question[];
};

export type CheckResultEntry = {
  quizId: string;
  questionIndex: number;
  isCorrect: boolean;
};

export type SelectedOption = {
  quizId: string;
  questionIndex: number;
  optionText: string;
};

export type QuizInitialState = {
  isStarted: boolean;
  resultsDisplayed: boolean;
  currentId?: string;
  currentQuiz?: CurrentQuiz;
  currentQuestionIndex: number;
  selectedOptions: SelectedOption[];
  checkResults: CheckResultEntry[];
};

const initialState: QuizInitialState = {
  isStarted: false,
  resultsDisplayed: false,
  currentQuestionIndex: 0,
  selectedOptions: [],
  checkResults: [],
};

const quizSlice = createSlice({
  name: "quiz",
  initialState: quizAdapter.getInitialState(initialState),
  reducers: {
    startQuiz(state, action: PayloadAction<string>) {
      let quizId = action.payload;
      utils.assertQuizHasQuestions(state.entities[quizId]);
      state.isStarted = true;
      state.currentId = quizId;
      state.currentQuiz = state.entities[quizId];
      let notAsweredQuestions = selectQuestionIndexesWithNoAnswers(state, quizId);
      if (notAsweredQuestions.length > 0) {
        state.currentQuestionIndex = notAsweredQuestions[0];
      } else {
        state.currentQuestionIndex = 0; //add count of questions check
      }
      state.resultsDisplayed = false;
    },
    reset(state) {
      state.selectedOptions = [];
      state.checkResults = [];
      saveProgress([], []);
    },
    displayQuestion(state, action: PayloadAction<number>) {
      if (state.currentQuiz === undefined) {
        throw new Error("Can't display question since quiz is not loaded yet");
      }
      let index = action.payload;
      let questionsCount = state.currentQuiz.questions.length;
      if (index < 0 || index >= questionsCount) {
        throw new Error(`Question index out of bounds [0, ${questionsCount - 1}]`);
      }
      state.currentQuestionIndex = index;
      state.resultsDisplayed = false;
    },

    selectOption(state, action: PayloadAction<string>) {
      let optionText = action.payload;
      if (state.currentId === undefined) {
        throw new Error("Current quiz id is undefined");
      }
      if (utils.isOptionAlreadySelected(state, optionText)) {
        state.selectedOptions = utils.removeSelectionOption(state, optionText);
      } else {
        state.selectedOptions.push({
          optionText,
          questionIndex: state.currentQuestionIndex,
          quizId: state.currentId,
        });
      }
      saveProgress(state.selectedOptions, state.checkResults);
    },

    checkUserAnswer(state) {
      if (state.currentQuiz === undefined || state.currentId === undefined) {
        throw new Error("Quiz is not defined");
      }
      let correctOptions = utils.currentCorrectOptions(state);
      let optsSelectedByUser = utils.optionsSelectedByUser(state);

      state.checkResults.push({
        quizId: state.currentId,
        questionIndex: state.currentQuestionIndex,
        isCorrect: arraysEquals(optsSelectedByUser, correctOptions),
      });
      saveProgress(state.selectedOptions, state.checkResults);
    },
    incorporateProgress(state, action: PayloadAction<CourseProgress>) {
      let progress = action.payload;
      state.checkResults = progress.checkResults;
      state.selectedOptions = progress.selectedOptions;
    },
    displayResults(state, action: PayloadAction<boolean>) {
      state.resultsDisplayed = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchCourse.fulfilled, (state, action) => {
      quizAdapter.setAll(state, action.payload.quizes);
    });
  },
});

export function quizesForArticle(state: RootState, articleId: string): Quiz[] {
  let allQuizes = Object.values(state.quiz.entities) as Quiz[];
  let res = allQuizes.filter((quiz) => quiz.forArticleId === articleId);
  if (res.length !== 0) {
    return res;
  } else {
    return [];
  }
}

export const selectQuestions = (state: RootState, quizId?: string): Question[] => {
  if (quizId !== undefined) {
    return state.quiz.entities[quizId]?.questions ?? [];
  }
  if (state.quiz.currentId === undefined || state.quiz.currentQuiz === undefined) {
    throw new Error("Current quiz is undefined and quizId param is not provided");
  }
  return state.quiz.currentQuiz.questions;
};

export const selectQuestion = (state: RootState, qIndex?: number): Question => {
  if (state.quiz.currentQuiz !== undefined) {
    let index = qIndex ?? state.quiz.currentQuestionIndex;
    return Object.values(state.quiz.currentQuiz.questions)[index];
  } else {
    return {
      text: "",
      textContent: [],
      options: [],
    };
  }
};

export const selectCurrentQuestionIndex = (state: RootState): number => {
  return state.quiz.currentQuestionIndex;
};

export const selectIsQuestionChecked = (state: RootState, quizId?: string, qIndex?: number) => {
  if (state.quiz.currentQuiz === undefined) {
    throw new Error("Current quiz is undefined");
  }
  if (qIndex === undefined) {
    qIndex = state.quiz.currentQuestionIndex;
  }
  if (quizId === undefined) {
    quizId = state.quiz.currentId;
  }
  return state.quiz.checkResults.find((r) => r.quizId === quizId && r.questionIndex === qIndex) !== undefined;
};

export const IsAnswerProvided = (state: RootState, quizId?: string, qIndex?: number) => {
  let opts = utils.optionsSelectedByUser(state.quiz, quizId, qIndex);
  return opts.length > 0;
};

export type QuizPercentageOfCompletion = {
  totalQuestions: number;
  questionsAnswered: number;
  completionPct: number;
};

export const selectPercentageOfCompletion = (state: RootState, quizId: string): QuizPercentageOfCompletion => {
  let allQuestions = selectQuestions(state, quizId);
  let answered = allQuestions.filter((_, ind) => IsAnswerProvided(state, quizId, ind));
  let completionPct = (answered.length / allQuestions.length) * 100;
  return {
    totalQuestions: allQuestions.length,
    questionsAnswered: answered.length,
    completionPct,
  };
};

export const selectNumberOfCorrectlyAnsweredQuestions = (state: RootState, quizId: string): number => {
  let allQuestions = selectQuestions(state, quizId);
  return allQuestions.map((_, ind) => selectIsCorrectlyAnswered(state, quizId, ind)).filter((r) => r === true).length;
};

export type QuizCompletionStats = {
  totalQuizes: number;
  completedQuizes: number;
  completionShare: number;
};

export const selectCompletedQuizesStats = (state: RootState): QuizCompletionStats => {
  let totalQuizes = state.quiz.ids.length;
  let completedQuizesList = state.quiz.ids
    .map((id) => selectPercentageOfCompletion(state, id.toString()))
    .filter((stats) => stats.totalQuestions === stats.questionsAnswered);
  return {
    totalQuizes,
    completedQuizes: completedQuizesList.length,
    completionShare: Math.round(completedQuizesList.length / totalQuizes),
  };
};

export type QuestionCompletionStats = {
  totalQuestions: number;
  completedQuestions: number;
  completionShare: number;
};

export const selectQuestionCompletionStats = (state: RootState): QuestionCompletionStats => {
  let allQuestions = Object.values(state.quiz.entities).flatMap((quiz) =>
    (quiz as Quiz).questions.map((q, i) => ({
      index: i,
      quizId: quiz?.id ?? "",
    }))
  );
  let answeredCount = allQuestions.filter((q) => IsAnswerProvided(state, q.quizId, q.index) === true).length;
  return {
    totalQuestions: allQuestions.length,
    completedQuestions: answeredCount,
    completionShare: Math.round((answeredCount / allQuestions.length) * 100),
  };
};

export type CorrectQuestionsStats = {
  totalQuestions: number;
  correctlyAnswered: number;
};

export const selectCorrectQuestionsStats = (state: RootState): CorrectQuestionsStats => {
  let allQuestions = Object.values(state.quiz.entities).flatMap((quiz) =>
    (quiz as Quiz).questions.map((q, i) => ({
      index: i,
      quizId: quiz?.id ?? "",
    }))
  );
  let correctCount = allQuestions.filter((q) => selectIsCorrectlyAnswered(state, q.quizId, q.index)).length;

  return {
    totalQuestions: allQuestions.length,
    correctlyAnswered: correctCount,
  };
};

export function selectQuizCompletionStats(state: RootState, quizId: string): CorrectQuestionsStats {
  let allQuestions = selectQuestions(state, quizId);
  let correctCount = allQuestions.filter((_, i) => selectIsCorrectlyAnswered(state, quizId, i)).length;

  return {
    totalQuestions: allQuestions.length,
    correctlyAnswered: correctCount,
  };
}

export const selectIsCorrectlyAnswered = (state: RootState, quizId?: string, qIndex?: number): boolean | undefined => {
  if (qIndex === undefined) {
    qIndex = state.quiz.currentQuestionIndex;
  }
  if (quizId === undefined) {
    quizId = state.quiz.currentId;
  }
  let resultEntry = state.quiz.checkResults.find((r) => r.quizId === quizId && r.questionIndex === qIndex);
  return resultEntry?.isCorrect;
};

export const selectSelectedOptions = (state: RootState, qIndex?: number): SelectedOption[] => {
  if (state.quiz.currentQuiz === undefined) {
    throw new Error("No quiz");
  }
  if (qIndex === undefined) {
    qIndex = state.quiz.currentQuestionIndex;
  }

  return state.quiz.selectedOptions.filter(
    (opt) => opt.quizId === state.quiz.currentId && opt.questionIndex === qIndex
  );
};

export const selectQuestionIndexesWithNoAnswers = (
  state: EntityState<Quiz> & QuizInitialState,
  quizId: string
): Array<number> => {
  let questions = state.entities[quizId]?.questions ?? new Array<Question>();
  let checkedIndexes = questions
    .map((_, ind) => {
      let selectedOptions = utils.optionsSelectedByUser(state, quizId, ind);
      return selectedOptions.length === 0 ? ind : -1;
    })
    .filter((v) => v !== -1);
  return checkedIndexes;
};

export function selectIsQuizComplete(state: RootState, quizId?: string): boolean {
  if (quizId === undefined) {
    quizId = state.quiz.currentId;
  }
  if (quizId === undefined) {
    return false;
  }
  let questionsInCurrQuiz = selectQuestions(state, quizId);
  return questionsInCurrQuiz.every((_, i) => selectIsQuestionChecked(state, quizId, i));
}

export function selectQuizWithId(state: RootState, quizId: string): Quiz {
  if (Object.keys(state.quiz.entities).find((id) => id === quizId) === undefined) {
    throw new Error(`No quiz with id = <${quizId}>`);
  }
  return state.quiz.entities[quizId] as Quiz;
}

export function selectNumberOfUncheckedQuizes(state: RootState, articleId: string): number {
  let allQuizes = quizesForArticle(state, articleId);
  return allQuizes.filter(q => selectIsQuizComplete(state, q.id)).length;
}

export function selectAreAllQuizesChecked(state: RootState, articleId: string): boolean {
  let allQuizes = quizesForArticle(state, articleId);
  return allQuizes.every((q) => selectIsQuizComplete(state, q.id));
}

export function selectNextQuiz(state: RootState, quizId: string): Quiz | undefined {
  debugger;
  let quiz = state.quiz.entities[quizId];
  if (quiz === undefined) {
    throw new Error(`No quiz with id = <${quizId}>`);
  }
  let allQuizes = quizesForArticle(state, quiz.forArticleId);
  let quizIndex = allQuizes.map((q) => q.id).indexOf(quiz.id);
  if (quizIndex !== allQuizes.length - 1) {
    return allQuizes[quizIndex];
  } else {
    return undefined;
  }
}

export const { startQuiz, displayQuestion, selectOption, checkUserAnswer, incorporateProgress, reset, displayResults } =
  quizSlice.actions;
export default quizSlice.reducer;
