// libs
import { useEffect } from "react";
import styled from "styled-components";
import { useSwiper } from "swiper/react";

//components
import { ArticleTag } from "../../features/datacontext/dbcontext";
import { ThemeType } from "../../themes";
import NextArticleLink from "../NextArticleLink";
import { QuizButton } from "../ViewArticle/QuizButton";
import { GraphicsLayout, TextLayout } from "./Layouts";
import SlideNavPanel from "./SlideNavPanel";

const SlideTitle = styled.h1`
  font-size: 2rem;
  font-weight: bold;
  color: ${(props: ThemeType) => props.theme.button.bg};
  margin: 0;

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

const TitleContainer = styled.div<ThemeType>`
  border-bottom: 2px solid rgb(200, 200, 200);
  display: flex;
  align-items: center;
  padding-bottom: 1rem;
  margin-bottom: 2rem;
  background-color: ${(props: ThemeType) => props.theme.panel.bg};
`;

const SlideFluidContainer = styled.div<ThemeType>`
  background-color: ${(props: ThemeType) => props.theme.panel.bg};
  padding: 0;
  color: ${(props: ThemeType) => props.theme.panel.color};
`;

type SlideContentPresenterProps = {
  articleId: string;
  totalSlides: number;
  slideIndex: number;
  content: Array<ArticleTag>;
};

export default function SlideContentPresenter(props: SlideContentPresenterProps) {
  let { articleId, slideIndex, totalSlides, content } = props;
  let header = content.filter((c) => c.tagName === "h");
  let graphicContent = content.filter((c) => c.tagName === "img");
  let textContent = content.filter((c) => c.tagName !== "img" && c.tagName !== "h" && c.content !== "");
  let swiper = useSwiper();

  useEffect(() => {
    swiper.slideTo(0);
  }, [content, swiper]);

  return (
    <>
      <TitleContainer className="title-container">
        <SlideTitle>{header[0].content}</SlideTitle>
        {slideIndex === 0 && (
          <SlideNavPanel
            onNextButtonClick={() => {
              swiper.slideNext();
            }}
          />
        )}
        {slideIndex > 0 && (
          <SlideNavPanel
            onNextButtonClick={() => {
              swiper.slideNext();
            }}
            onPrevButtonClick={() => {
              swiper.slidePrev();
            }}
          />
        )}
      </TitleContainer>
      <SlideFluidContainer className="container-fluid">
        <div className="row">
          {graphicContent.length > 0 && <GraphicsLayout textTags={textContent} graphicTags={graphicContent} />}
          {graphicContent.length === 0 && <TextLayout textTags={textContent} />}
          <div style={{ marginTop: "1rem" }}>
            <QuizButton articleId={articleId} header={header[0].content} />
          </div>
        </div>
      </SlideFluidContainer>
    </>
  );
}

export function GoNextArticleSlide(props: { currentArticleId: string }) {
  let { currentArticleId } = props;
  let swiper = useSwiper();

  return (
    <>
      <TitleContainer className="title-container">
        <SlideTitle>Следующая статья</SlideTitle>
        <SlideNavPanel
          onPrevButtonClick={() => {
            swiper.slidePrev();
          }}
        />
      </TitleContainer>
      <SlideFluidContainer className="container-fluid">
        <div className="row" style={{ paddingTop: "4rem", paddingBottom: "4rem" }}>
          <div className="col-12">
            <NextArticleLink
              currentArticleId={currentArticleId}
              onClick={() => {
                swiper.slideTo(0);
              }}
            />
          </div>
        </div>
      </SlideFluidContainer>
    </>
  );
}
