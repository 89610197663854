import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Course, CourseSummary, getCourseSummaryFromApi } from "../datacontext/dbcontext";
import { fetchCourse } from "./articleSlice";

interface CourseDetails {
    id: string;
    title: string;
    shortDescription: string;
    imageSrc?: string;
}

const initialState: CourseDetails = {
    id: "",
    title: "",
    shortDescription: "",
};

export const fetchCourseSummary = createAsyncThunk("course/fetchCourseSummary", async (courseId: string) => {
    const response = await getCourseSummaryFromApi(courseId);
    return response;
});

const courseSlice = createSlice({
    name: "course",
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(fetchCourse.fulfilled, (state, action: PayloadAction<Course>) => {
            let course = action.payload;
            state.id = course.id;
            state.title = course.title;
            state.shortDescription = course.shortDescription;
            state.imageSrc = course.imageSrc;
        }).addCase(fetchCourseSummary.fulfilled, (state, action: PayloadAction<CourseSummary>) => {
            let summary = action.payload;
            state.id = summary.id;
            state.title = summary.title;
            state.shortDescription = summary.shortDescription;
        });
    },
});

export default courseSlice.reducer;
