import { Theme } from ".";

export const lightTheme: Theme = {
  colorScheme: "light",
  appBg: "rgb(240, 240, 240)",
  textColor: "black",
  highlightedTextColor: "#006aaa",
  button: {
    bg: "#006aaa",
    color: "white",
    hover: {
      bg: "#006aaadb",
      color: "white",
    },
    disabled: {
      bg: "#4287b3",
      color: "white",
    },
  },
  modal: {
    bg: "white",
    color: "black",
    overlayBg: "rgba(0, 0, 0, 0.3)",
    shadow: "0px 0px 12px 6px rgb(146 146 146)",
    borderStyle: "1px solid gray",
    closeButtonColor: "black",
  },
  answerOption: {
    background: "rgb(240, 240, 240)",
  },
  quizNavBtn: {
    bg: "rgb(240, 240, 240)",
    color: "black",
    active: {
      bg: "#006aaa",
      color: "white",
    },
  },
  correctnessInd: {
    correct: "green",
    incorrect: "red",
  },
  correctOptionIndicatorColor: "green",
  incorrectOptionIndicatorColor: "red",
  articleImageShadow: "0px 0px 11px 4px #d9d9d9",
  courseStructure: {
    articleHeaderColor: "black",
    shortDescriptionColor: "black",
    addInfoColor: "black",
  },
  panel: {
    bg: "white",
    color: "black",
    border: "1px solid rgb(211, 211, 211)",
  },
  quizCard: {
    border: "1px solid rgb(221, 221, 221)",
    shadow: "0px 0px 10px 3px rgba(0, 0, 0, 0.1)",
  },
  quizProgressChart: {
    background: "rgb(230, 230, 230)",
    progress: "#006aaa",
  },
  quizResultRowHoverBg: "rgb(240, 240, 240)",
};
