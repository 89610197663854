import { neonTheme } from "./neon";
import { lightTheme } from "./light";

export interface ElementStyle {
  bg: string;
  color: string;
  hover?: ElementStyle;
  disabled?: ElementStyle;
  active?: ElementStyle;
}

export type Background = {
  background?: string;
  hoverBackground?: string;
};

export type CorrectnessIndicatorBg = {
  correct: string;
  incorrect: string;
};

export interface ModalTheme extends ElementStyle {
  overlayBg: string;
  shadow: string;
  borderStyle: string;
  closeButtonColor: "black" | "white";
}

export interface QuizProgressPieChartColors {
  progress: string;
  background: string;
}

export type ThemeColorScheme = "dark" | "light";

export type Theme = {  
  colorScheme: ThemeColorScheme;
  appBg: string;
  textColor: string;
  highlightedTextColor: string,
  button: ElementStyle;
  modal: ModalTheme;
  answerOption: Background;
  quizNavBtn: ElementStyle;
  correctnessInd: CorrectnessIndicatorBg;
  correctOptionIndicatorColor: string;
  incorrectOptionIndicatorColor: string;
  articleImageShadow: string;
  courseStructure: {
    articleHeaderColor: string;
    shortDescriptionColor: string;
    addInfoColor: string;
  },
  panel: {
    bg: string,
    color: string,
    border: string
  },
  quizCard: {
    border: string,
    shadow: string
  },
  quizProgressChart: QuizProgressPieChartColors,
  quizResultRowHoverBg: string;
};

export type ThemeType = {
  theme: Theme;
};

export function getColorSchemeObject(colorScheme: ThemeColorScheme): Theme {
  switch (colorScheme) {
    case "light":
      return lightTheme;
    case "dark":
      return neonTheme;
    default:
      return lightTheme;
  }
}

export { neonTheme, lightTheme };
