import { RootState } from "../../app/store";
import { useAppSelector } from "../../app/hooks";
import { findArticle } from "../../features/course/articleSlice";

import SwiperSlides from "../ArticleContent/SwiperSlides";

type ViewArticleProps = {
  courseId: string;
  articleId: string;
};

export default function ViewArticle(props: ViewArticleProps) {
  let article = useAppSelector((state: RootState) => {
    if (props.articleId !== undefined) {
      let article = findArticle(state, props.articleId);
      return article;
    } else {
      return undefined;
    }
  });  
  return <SwiperSlides article={article} currentArticleId={props.articleId} />;
}
