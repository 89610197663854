import styled from "styled-components";
import { useAppSelector } from "../../app/hooks";
import { ArticleTag, Question } from "../../features/datacontext/dbcontext";
import { selectIsQuestionChecked, selectQuestion } from "../../features/quiz/quiz-slice";
import AnswerResult from "./AnswerResult";
import GoForwardButton from "./GoForwardButton";
import { QuizCompletionProps } from "./QuizModal";
import QuizResult from "./QuizResult";
import UiOption from "./UiOption";

const OptionsHeader = styled.p`
  margin-top: 1rem;
  font-weight: bold;
  text-decoration: underline;
`;

const QuestionImageContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const ImageInQuestion = styled.img`
  width: 100%;
  border-radius: 0.5rem;
`;

const ImageCaption = styled.p`
  padding-top: 0.5rem;
  text-align: center;
  font-size: 0.8rem;
`;

type ParaHasError = {
  hasError?: boolean;
};

const QuestionParagraph = styled.p<ParaHasError>`
  font-size: 1rem;
  font-weight: bold;
  color: ${(props) => (props.hasError === true ? "red" : "inherit")};
  margin-bottom: 0.5rem;
`;

const QuestionContainer = styled.div`
  margin-top: 1rem;
`;

const FluidContainer = styled.div`
  padding: 0;
  margin-bottom: 1rem;
`;

type ContentOfTags = {
  tags: ArticleTag[];
};

function QuestionTextContent(props: ContentOfTags) {
  let { tags } = props;
  if (tags.find((t) => t.tagName === "img")) {
    throw new Error("Only text tags are supported");
  }
  return (
    <>
      {tags.map((t: ArticleTag, i: number) => {
        switch (t.tagName) {
          case "h":
            return <h2 key={i}>{t.content}</h2>;
          case "p":
            return <QuestionParagraph key={i}>{t.content}</QuestionParagraph>;
          case "li":
            return <li key={i}>{t.content}</li>;
          default:
            return (
              <QuestionParagraph hasError key={i}>
                {t.content}
              </QuestionParagraph>
            );
        }
      })}
    </>
  );
}

function QuestionGraphicContent(props: ContentOfTags) {
  if (!props.tags.every((t) => t.tagName === "img")) {
    throw new Error("Every tag must be image");
  }
  return (
    <>
      {props.tags.map((im, i) => (
        <QuestionImageContainer key={`img_container_${i}`}>
          <ImageInQuestion key={`img_${i}`} src={im.imageSrc} alt={im.imageAlt} />
          <ImageCaption>{im.imageCaption}</ImageCaption>
        </QuestionImageContainer>
      ))}
    </>
  );
}

const AnswerOptionsContainer = styled.div`
  margin-bottom: 1.5rem;
`;

function AnswerOptions(props: { question: Question }) {
  return (
    <AnswerOptionsContainer className="answer-options-container">
      {props.question.options.map((opt) => (
        <UiOption key={opt.text} opt={opt} />
      ))}
    </AnswerOptionsContainer>
  );
}

export default function QuestionView() {
  let currentQuestion = useAppSelector(selectQuestion);
  let imageContent = currentQuestion.textContent.filter((t) => t.tagName === "img");
  let textContent = currentQuestion.textContent.filter((t) => t.tagName !== "img");

  if (imageContent.length > 0) {
    return (
      <QuestionContainer>
        <FluidContainer className="container-fluid">
          <div className="row">
            <div className="col-12 col-md-4">
              <QuestionGraphicContent tags={imageContent} />
            </div>
            <div className="col-12 col-md-8">
              <QuestionTextContent tags={textContent} />
              <AnswerResult />
              <OptionsHeader>Варианты ответа</OptionsHeader>             
              <AnswerOptions question={currentQuestion} />
              <GoForwardButton />
            </div>
          </div>
        </FluidContainer>
      </QuestionContainer>
    );
  } else {
    return (
      <QuestionContainer>
        <QuestionTextContent tags={textContent} />
        <AnswerResult />
        <OptionsHeader>Варианты ответа</OptionsHeader>
        <AnswerOptions question={currentQuestion} />
        <GoForwardButton />
      </QuestionContainer>
    );
  }
}
