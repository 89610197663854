import { useAppDispatch, useAppSelector } from "../../app/hooks";
import styled from "styled-components";
import {
  checkUserAnswer,
  displayQuestion,
  displayResults,
  IsAnswerProvided,
  selectCurrentQuestionIndex,
  selectIsQuestionChecked,
  selectIsQuizComplete,
  selectQuestions,
} from "../../features/quiz/quiz-slice";
import { lightTheme, neonTheme, ThemeType } from "../../themes";

const GoButton = styled.button<ThemeType>`
  outline: none;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 0.25rem;
  background-color: ${(props: ThemeType) => props.theme.button.bg};
  color: ${(props: ThemeType) => props.theme.button.color};

  &:disabled {
    cursor: no-drop;
    background-color: ${(props: ThemeType) => props.theme.button.disabled?.bg};
    color: ${(props: ThemeType) => props.theme.button.disabled?.color};
  }

  &:hover {
    background-color: ${(props: ThemeType) => props.theme.button.hover?.bg};
    color: ${(props: ThemeType) => props.theme.button.hover?.color};
  }
`;

GoButton.defaultProps = {
  theme: lightTheme,
};

export default function GoForwardButton() {
  let dispatch = useAppDispatch();
  let questions = useAppSelector(selectQuestions);
  let currentQuestionIndex = useAppSelector(selectCurrentQuestionIndex);
  let isAnswerProvided = useAppSelector(IsAnswerProvided);
  let beenChecked = useAppSelector(selectIsQuestionChecked);
  let isQuizComplete = useAppSelector(selectIsQuizComplete);

  function checkAnswer() {
    dispatch(checkUserAnswer());
  }

  function goForward() {
    if (isQuizComplete && currentQuestionIndex == questions.length - 1) {
      dispatch(displayResults(true));
    } else {
      if (currentQuestionIndex < questions.length - 1) {
        dispatch(displayQuestion(currentQuestionIndex + 1));
      }
    }
  }

  let goForwardButton = <GoButton onClick={goForward}>Далее</GoButton>;

  let checkButton = (
    <GoButton onClick={checkAnswer} disabled={!isAnswerProvided}>
      Проверить
    </GoButton>
  );

  return beenChecked ? goForwardButton : checkButton;
}
