import styled from "styled-components";
import { useAppDispatch } from "../../app/hooks";
import { setColorScheme } from "../../features/app/appSlice";
import { NeonModal, NeonModalDialog, NeonModalProps } from "../Common/NeonModal";
import darkImage from "./Scheme images/Dark scheme.svg";
import lightImage from "./Scheme images/Light scheme.svg";

const StyledThemeImage = styled.img`
  border-radius: 1rem;
  cursor: pointer;

  &:hover {
    transform: scale(1.01);
  }
`;

const StyledSettingsSectionTitle = styled.h1`
  font-size: 1.1rem;
  font-weight: bold;
  border-bottom: 1px solid rgb(200, 200, 200);
  margin-bottom: 1rem;
`;

const StyledColorSchemeTitle = styled.p`
  font-size: 1rem;
  margin: 0;
  margin-bottom: 0.5rem;
`;

export default function SettingsModal(props: NeonModalProps) {
  let { modalIsOpen, closeModal } = props;
  let dispatch = useAppDispatch();

  function useLightTheme() {
    dispatch(setColorScheme("light"));
  }

  function useDarkTheme() {
    dispatch(setColorScheme("dark"));
  }

  return (
    <NeonModalDialog title="Настройки" modalIsOpen={modalIsOpen} closeModal={closeModal}>
      {/* <div className="d-flex">
        <button onClick={useLightTheme}>Light theme</button>
        <span className="me-1"></span>
        <button onClick={useDarkTheme}>Dark theme</button>
      </div> */}
      <div className="container-fluid mt-4">
        <div className="row">
          <StyledSettingsSectionTitle>Color scheme</StyledSettingsSectionTitle>
          <div className="col-6">
            <StyledColorSchemeTitle>Dark</StyledColorSchemeTitle>
            <StyledThemeImage src={darkImage} className="img-fluid" onClick={useDarkTheme} />
          </div>
          <div className="col-6">
            <StyledColorSchemeTitle>Light</StyledColorSchemeTitle>
            <StyledThemeImage src={lightImage} className="img-fluid" onClick={useLightTheme} />
          </div>
        </div>
      </div>
    </NeonModalDialog>
  );
}
