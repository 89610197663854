import { useEffect, useState } from "react";
import { useAppSelector } from "../../app/hooks";
import "./spinner.scss";

export default function Spinner() {
  let courseTitle = useAppSelector((state) => state.course.title);
  let courseDescription = useAppSelector((state) => state.course.shortDescription);

  return (
    <div id="spinner">
      <h1 className="title">{courseTitle}</h1>
      <p className="description">{courseDescription}</p>
      <div className="lds-facebook">
        <div></div>
        <div></div>
        <div></div>
      </div>
      <p>Подождите...</p>
    </div>
  );
}
