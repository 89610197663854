import { useState } from "react";
import styled from "styled-components";
import { ThemeType } from "../../themes";
import SettingsModal from "../SettingsModal";

import SettingsIcon from "./Settings.svg";

const Button = styled.button<ThemeType>`
  margin-top: auto;
  margin-bottom: 1rem;
  margin-right: 1rem;
  margin-left: 1rem;
  border: none;
  padding: 0.8rem;
  border-radius: 0.5rem;
  color: ${(props: ThemeType) => props.theme.button.color};
  background: ${(props: ThemeType) => props.theme.button.bg};

  &:hover {
    background: ${(props: ThemeType) => props.theme.button.hover?.bg};
    color: ${(props: ThemeType) => props.theme.button.hover?.color};
  }

  img {
    margin-right: 0.5rem;
  }

  @media (max-width: 768px) {
    display:none;
  }
`;

export default function SettingsButton() {
  let [modalIsOpen, setIsModalOpen] = useState(false);

  function onCloseModal() {
    setIsModalOpen(false);
  }

  return (
    <>
      <Button
        onClick={() => {
          //dispatch(flipColorScheme());
          setIsModalOpen(true);
        }}
      >
        <img width="18px" src={SettingsIcon} alt="" />
        Settings
      </Button>
      <SettingsModal modalIsOpen={modalIsOpen} closeModal={onCloseModal} />
    </>
  );
}
