import styled from "styled-components";
import { useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import QuizProgress from "../QuizProgressBar";

const TableTitle = styled.div`
  font-size: 0.9rem;

  padding-bottom: 2rem;
  color: rgb(168, 168, 168);
  text-decoration: underline;

  @media (max-width: 991px) {
    display: none;
  }
`;

export default function ArticleProgressTable() {
  let quizIds = useAppSelector((state: RootState) => state.quiz.ids).map((id) => id.toString());

  return (
    <>
      <div className="row">
        <TableTitle className="col-2">Статья</TableTitle>
        <TableTitle className="col-3">Тест</TableTitle>
        <TableTitle className="col-3">Прогресс</TableTitle>
        <TableTitle className="col-2">Результат</TableTitle>
        <TableTitle className="col-2">Правильных</TableTitle>
      </div>
      {quizIds.map((id) => (
        <QuizProgress key={id} quizId={id} />
      ))}
    </>
  );
}
