import { useNavigate } from "react-router-dom";
import styled, { ThemeProps } from "styled-components";
import { useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { selectNumberOfCorrectlyAnsweredQuestions, selectPercentageOfCompletion } from "../../features/quiz/quiz-slice";
import { ThemeType } from "../../themes";
import CompletionProgressBar from "./CompletionProgressBar";

type QuizProgressProps = {
  quizId: string;
};

const TableCol = styled.div`
  display: flex;
  align-items: center;
`;

const StyledArticleTitle = styled.span`
  color: rgb(100, 100, 100);
  font-size: 1rem;
  margin-bottom: 0.5rem;
  padding-right: 0.5rem;
`;

const StyledQuizName = styled.span`
  @media (max-width: 991px) {
    margin-bottom: 0.5rem;
  }
`;

const StyledQuizRow = styled.div<ThemeType>`
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-radius: 1rem;
  font-size: 1rem;
  cursor: pointer;

  &:hover {
    background-color: ${(props: ThemeType) => props.theme.quizResultRowHoverBg};
  }

  @media (max-width: 991px) {
    margin-bottom: 2rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid rgb(230, 230, 230);
  }
`;

export default function QuizProgress(props: QuizProgressProps) {
  let { quizId } = props;
  let quizName = useAppSelector((state: RootState) => state.quiz.entities[quizId]?.title);
  let courseId = useAppSelector((state) => state.course.id);
  let articleTitle = useAppSelector((state: RootState) => {
    let articleId = state.quiz.entities[quizId]?.forArticleId;
    if (articleId === undefined) {
      return "-";
    }
    return state.article.entities[articleId]?.title ?? "-";
  });

  let { totalQuestions, questionsAnswered } = useAppSelector((state) => selectPercentageOfCompletion(state, quizId));
  let correctlyAnsweredQuestions = useAppSelector((state) => selectNumberOfCorrectlyAnsweredQuestions(state, quizId));
  let correctShare = Math.round((correctlyAnsweredQuestions / totalQuestions) * 100);

  let navigate = useNavigate();

  function gotoQuiz() {
    navigate("/quizpage/" + courseId + "/" + quizId);
  }

  return (
    <>
      <StyledQuizRow className="row" onClick={gotoQuiz}>
        <TableCol className="col-lg-2 col-12">
          <StyledArticleTitle className="table-text">{articleTitle}</StyledArticleTitle>
        </TableCol>
        <TableCol className="col-lg-3 col-12">
          <StyledQuizName>{quizName}</StyledQuizName>
        </TableCol>
        <TableCol className="col-lg-3 col-12 d-flex">
          <CompletionProgressBar quizId={quizId} />
        </TableCol>
        <TableCol className="col-lg-2 col-12">
          <span>
            {questionsAnswered}/{totalQuestions} пройдено
          </span>
        </TableCol>
        <TableCol className="col-lg-2 col-12">
          <span>
            {correctlyAnsweredQuestions}/{totalQuestions} ({`${correctShare}%`}) правильно
          </span>
        </TableCol>
      </StyledQuizRow>
    </>
  );
}
