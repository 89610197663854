// ------------- Main app component -------------

// import bootstrap styles first and index to change app font
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import "./app-font.css";
// import "./layout.scss";

//libs
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import { Provider } from "react-redux";

//components
import { store } from "./app/store";

//routes
import Progress from "./routes/progress";
import CourseRoute from "./routes/course";
import CourseInfoRoute from "./routes/course/info";
import QuizRoute from "./routes/quiz";
import ThemeWrapper from "./Components/Common/ThemeWrapper";
import QuizPage from "./routes/quiz/quiz-page";
import styled from "styled-components";
import LeftNavbar from "./Components/LeftNavbar";
import { ThemeType } from "./themes";
import { useAppSelector } from "./app/hooks";
import Spinner from "./Components/Spinner/Spinner";

const LeftNavbarWidth = '260px';
const NavbarHeightOnPhone = '52px';

const AppContainer = styled.div<ThemeType>`
  position: fixed;
  inset: 0;
  background: transparent;

  @media (min-width: 768px) {
    background: ${(props: ThemeType) => props.theme.appBg};
  }
`;

const AppNavBar = styled.div<ThemeType>`
  position: absolute;
  height: ${NavbarHeightOnPhone};
  bottom: -1px;
  left: 0;
  right: 0;
  background: ${(props: ThemeType) => props.theme.panel.bg};
  color: ${(props: ThemeType) => props.theme.panel.color};
  border-top: 1px solid #dcdcdc;
  box-shadow: 0px 0px 5px 5px rgb(0, 0, 0, 0.01);
  z-index: 2;
  border-radius: 0;

  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;

  @media (min-width: 768px) {
    border-radius: 1rem;
    left: 1rem;
    top: 1rem;
    height: calc(100% - 2rem);
    width: ${LeftNavbarWidth};
    border: ${(props: ThemeType) => props.theme.panel.border};
  }
`;

const AppWorkspace = styled.div`
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  bottom: ${NavbarHeightOnPhone};
  overflow-y: auto;
  padding: 0;

  @media (min-width: 768px) {
    left: calc(${LeftNavbarWidth} + 2rem);
    top: 0;
    bottom: 0;
    width: calc(100% - ${LeftNavbarWidth} - 2rem);
    padding: 0;
    padding-top: 1rem;
    padding-right: 1rem;
    padding-bottom: 1rem;
  }
`;

function RouteContainer() {
  let isLoading = useAppSelector(state => state.app.status === "loading");

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <AppContainer id="app-container">
      <AppNavBar id="app-navbar">
        <LeftNavbar />
      </AppNavBar>
      <AppWorkspace id="app-workspace-new">
        <Outlet />
      </AppWorkspace>
    </AppContainer>
  );
}

export default function App() {
  return (
    <Provider store={store}>
      <ThemeWrapper>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<RouteContainer />}>
              <Route path="/course/:id" element={<CourseInfoRoute />} />
              <Route path="/course/:id/:articleId" element={<CourseRoute />} />
              <Route path="/quiz/:id" element={<QuizRoute />} />
              <Route path="/quizpage/:courseId/:quizId" element={<QuizPage />} />
              <Route path="/progress" element={<Progress />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </ThemeWrapper>
    </Provider>
  );
}
