import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { AnswerOption } from "../../features/datacontext/dbcontext";

import { selectIsQuestionChecked, selectOption, selectSelectedOptions } from "../../features/quiz/quiz-slice";
import { ThemeType } from "../../themes";

import correctIcon from "./icons/Correct.svg";
import incorrectIcon from "./icons/Incorrect.svg";

type AnswerOptionProps = {
  opt: AnswerOption;
};

interface IsOptionsSelected {
  selected: boolean;
}

const Para = styled.p<ThemeType>`
  margin: 0;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
`;

const OptionElement = styled(Para)<ThemeType & IsOptionsSelected>`
  padding: 0.5rem;
  cursor: pointer;
  border-radius: 0.5rem;
  background-color: ${(props) => (props.selected === true ? "gray" : "inherit")};
  color: ${(props) => (props.selected === true ? "white" : "inherit")};

  &:hover {
    background-color: ${(props: ThemeType & IsOptionsSelected) =>
      props.selected === true ? "gray" : props.theme.answerOption.hoverBackground};
  }
`;

export default function UiOption(props: AnswerOptionProps) {
  let dispatch = useAppDispatch();
  let { text, isCorrect } = props.opt;
  let isSelectedByUser = useAppSelector((state) => {
    let options = selectSelectedOptions(state);
    return options.map((opt) => opt.optionText).indexOf(text) !== -1;
  });
  let hasChecked = useAppSelector(selectIsQuestionChecked);

  function answerOptionClick() {
    if (!hasChecked) {
      dispatch(selectOption(text));
    }
  }

  function CorrectnessIndicator() {
    if (!hasChecked) {
      return <></>;
    }
    if (isSelectedByUser) {
      if (isCorrect) {
        return <img width="16" src={correctIcon} alt="" className="me-1" />;
      } else {
        return <img width="16" src={incorrectIcon} alt="" className="me-1" />;
      }
    } else {
      if (isCorrect) {
        return <img width="16" src={correctIcon} alt="" className="me-1" />;
      }
    }
  }

  return (
    <div className="d-flex align-items-center">
      {CorrectnessIndicator()}
      <OptionElement selected={isSelectedByUser} onClick={answerOptionClick}>
        {text}
      </OptionElement>
    </div>
  );
}
