import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { AppContainer } from "../../Components/Common/AppContainer";
import Panel from "../../Components/Common/Panel";
import QuizCard from "../../Components/QuizCard";
import Spinner from "../../Components/Spinner/Spinner";
import { initApp } from "../../features/app/app-initializer";
import { Quiz } from "../../features/datacontext/dbcontext";

export default function QuizRoute() {
  let dispatch = useAppDispatch();
  let params = useParams();
  let courseId = params.id !== undefined ? params.id : "";

  let quizes = useAppSelector((state) => Object.values(state.quiz.entities)) as Quiz[];
  let courseIsFetched = useAppSelector((state: RootState) => state.article.ids.length > 0);
  let isLoadingInitial = useAppSelector((state) => state.app.isLoadInitial);
  let isLoading = useAppSelector((state) => state.app.status === "loading");

  useEffect(() => {
    initApp(dispatch, courseId, isLoadingInitial);
  }, [dispatch, courseId, courseIsFetched]);

  if (isLoadingInitial) {
    return <>{isLoading && <Spinner />}</>;
  }

  return (
    <Panel title="Мои тесты">
      <div className="container-fluid">
        <div className="row">
          {quizes.map((q) => (
            <QuizCard quiz={q} key={q.id} />
          ))}
        </div>
      </div>
    </Panel>
  );
}
