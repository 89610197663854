import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchCourse } from "../course/articleSlice";
import {ThemeColorScheme} from "../../themes";

type AppArea = "progress" | "quiz" | "my-course";

interface AppState {
  isLoadInitial: boolean; //true if app is loading first time
  status: "idle" | "loading" | "fulfilled" | "error";
  activeArea: AppArea;
  errMessage: string;
  colorScheme: ThemeColorScheme;
}

const initialState: AppState = {
  isLoadInitial: true,
  status: "idle",
  errMessage: "",
  activeArea: "my-course",
  colorScheme: "light",
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    activateAppArea(state, action: PayloadAction<AppArea>) {
      state.activeArea = action.payload;
    },
    setColorScheme(state, action: PayloadAction<ThemeColorScheme>) {
      state.colorScheme = action.payload;
    },
    flipColorScheme(state) {
      if (state.colorScheme === "light") {
        state.colorScheme = "light";
      } else {
        state.colorScheme = "dark";
      }
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchCourse.fulfilled, (state) => {
        state.status = "fulfilled";
        state.isLoadInitial = false;
      })
      .addCase(fetchCourse.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCourse.rejected, (state) => {
        state.status = "error";
        state.errMessage = "Fail to fetch course. Please try again";
      });
  },
});

export const { activateAppArea, setColorScheme, flipColorScheme } = appSlice.actions;
export default appSlice.reducer;
