import { removeWhere } from "../../Utils/arrays";
import { AnswerOption, Quiz } from "../datacontext/dbcontext";
import { QuizInitialState } from "./quiz-slice";

export function assertQuizHasQuestions(q: Quiz | undefined) {
  if (q === undefined) {
    throw new Error("Quiz is undefined");
  }
  if (q.questions.length === 0) {
    throw new Error(`Quiz <${q.title}> has no questions`);
  }
}

export function removeSelectionOption(state: QuizInitialState, optionText: string) {
  if (state.currentId === undefined) {
    throw new Error("Quiz is undefined");
  }

  return removeWhere(
    state.selectedOptions,
    (opt) =>
      opt.quizId === state.currentId &&
      opt.questionIndex === state.currentQuestionIndex &&
      opt.optionText === optionText
  );

  // return state.selectedOptions.filter(
  //   (opt) =>
  //     opt.quizId === state.currentId &&
  //     opt.questionIndex === state.currentQuestionIndex &&
  //     opt.optionText !== optionText
  // );
}

export function isOptionAlreadySelected(state: QuizInitialState, optionText: string) {
  if (state.currentId === undefined) {
    throw new Error("Current quiz in undefined");
  }

  let opt = state.selectedOptions.find(
    (opt) =>
      opt.quizId === state.currentId &&
      opt.questionIndex === state.currentQuestionIndex &&
      opt.optionText === optionText
  );
  return opt !== undefined;
}

export function optionsForCurrentQuestion(state: QuizInitialState): AnswerOption[] {
  if (state.currentQuiz === undefined) {
    throw new Error("Quiz is undefined");
  }
  return state.currentQuiz.questions[state.currentQuestionIndex].options;
}

export function currentCorrectOptions(state: QuizInitialState): string[] {
  return optionsForCurrentQuestion(state)
    .filter((opt) => opt.isCorrect)
    .map((opt) => opt.text);
}

export function optionsSelectedByUser(state: QuizInitialState, quizId?: string, qIndex?: number): string[] {
  if (qIndex === undefined) {
    qIndex = state.currentQuestionIndex;
  }
  if (quizId === undefined) {
    quizId = state.currentId;
  }
  return state.selectedOptions
    .filter((opt) => opt.questionIndex === qIndex && opt.quizId === quizId)
    .map((opt) => opt.optionText);
}
