import { useAppSelector } from "../../app/hooks";
import { Article } from "../../features/datacontext/dbcontext";
import ArticleCard from "./article-card";

type CourseNavigationProps = {
  afterClickOnLink: () => void;
};

export default function CourseNavigation(props: CourseNavigationProps) {
  let { afterClickOnLink } = props;

  let articles = useAppSelector((state) => Object.values(state.article.entities)) as Article[];
  let courseId = useAppSelector((state) => state.course.id);

  return (
    <div
      className="container-fluid p-0"
      style={{
        marginTop: "1rem",
      }}
    >
      {articles.map((art) => (
        <ArticleCard key={art.id} courseId={courseId} article={art} afterClickOnLink={afterClickOnLink} />
      ))}
    </div>
  );
}
