import { Link } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";

// selectors
import { selectQuestionCompletionStats } from "../../features/quiz/quiz-slice";

// components

import CourseNavigation from "../CourseNavigation/CourseNavigation";
import Panel from "../Common/Panel";

// images

import articleImg from "./icons/Article.svg";
import quizImg from "./icons/Quiz.svg";
import questionImg from "./icons/Question.svg";

//styles

import styled from "styled-components";
import { ThemeType } from "../../themes";

const CourseInfoContainer = styled.div`
  color: ${(props: ThemeType) => props.theme.textColor};

  @media (max-width: 768px) {
    text-align: center;
  }
`;

const StyledCourseImage = styled.img`
  width: 100%;
  border-radius: 0.5rem;
`;

const StyledStatsPanel = styled.div`
  margin-right: 2rem;
  align-items: center;

  @media (max-width: 768px) {
    margin-bottom: 1rem;
  }

  img {
    width: 32px;
    margin-right: 0.5rem;
  }

  p {
    margin: 0;
  }

  a {
    color: ${(props: ThemeType) => props.theme.textColor};
  }
`;

type StyledStatsContainerProps = {
  fontColor: string;
};

const StyledStatsContainer = styled.div<StyledStatsContainerProps>`
  display: flex;
  justify-content: center;

  a,
  span {
    color: ${(props: StyledStatsContainerProps) => {
      return props.fontColor;
    }};
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const StartLearningButton = styled(Link)<ThemeType>`
  padding: 1rem 1.8rem;
  border: none;
  color: ${(props: ThemeType) => props.theme.button.color + " !important"};
  border-radius: 0.5rem;
  background-color: ${(props: ThemeType) => props.theme.button.bg};
  text-decoration: none;

  &:hover {
    background-color: ${(props: ThemeType) => props.theme.button.hover?.bg};
  }
`;

const NavigationContainer = styled.div`
  padding: 5rem 10rem;

  @media (max-width: 1200px) {
    padding: 1rem 0rem;
  }
`;

const TitleAndDescription = styled.div`
  position: absolute;
  bottom: 5%;
  left: 1rem;
  right: 1rem;
  background: rgba(0, 0, 0, 0.6);
  padding: 2rem;
  border-radius: 1rem;

  @media (max-width: 1200px) {
    padding: 1rem;
    inset: 1rem;
  }
`;

const CourseTitle = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 1rem;
  text-align: center;
  color: white;

  @media (max-width: 992px) {
    font-size: 1.5rem;
  }

  @media (max-width: 768px) {
    font-size: 1.1rem;
  }
`;

const CourseShortDescription = styled.p`
  font-weight: 300;
  text-align: center;
  color: white;
  font-size: 1.4rem;
  margin-bottom: 2rem;

  @media (max-width: 992px) {
    font-size: 1rem;
  }

  @media (max-width: 768px) {
    font-size: 0.75rem;
    margin-bottom: 0;
  }
`;

function CourseStatsComponent(props: { courseId: string; fontColor: string }) {
  let { courseId, fontColor } = props;
  let totalArticlesNumber = useAppSelector((state) => state.article.ids.length);
  let firstArticleId = useAppSelector((state) => (state.article.ids.length > 0 ? state.article.ids[0].toString() : ""));
  let countOfQuizes = useAppSelector((state) => state.quiz.ids.length);
  let { totalQuestions } = useAppSelector(selectQuestionCompletionStats);

  const getCaption = (n: number, zero: string, one: string, twoToFive: string, moreThenFive: string) => {
    if (n === 0) {
      return zero;
    } else if (n === 1) {
      return one;
    } else if (n >= 2 && n <= 5) {
      return twoToFive;
    } else {
      return moreThenFive;
    }
  };

  return (
    <StyledStatsContainer fontColor={fontColor}>
      <StyledStatsPanel>
        <img src={articleImg} alt="" />
        <Link to={`/course/${courseId}/${firstArticleId}`}>
          {totalArticlesNumber} {getCaption(totalArticlesNumber, "статей", "статья", "статьи", "статей")}
        </Link>
      </StyledStatsPanel>
      <StyledStatsPanel>
        <img src={quizImg} alt="" />
        <Link to={`/quiz/${courseId}`}>
          {countOfQuizes} {getCaption(countOfQuizes, "тестов", "тест", "теста", "тестов")}
        </Link>
      </StyledStatsPanel>
      <StyledStatsPanel>
        <img src={questionImg} alt="" />
        <span>
          {totalQuestions} {getCaption(totalQuestions, "вопросов", "вопрос", "вопроса", "вопросов")} в тестах
        </span>
      </StyledStatsPanel>
    </StyledStatsContainer>
  );
}

const StatsComponentContainerInImage = styled.div`
  @media (max-width: 992px) {
    display: none;
  }
`;

const StatsComponentContainerOutOfImage = styled.div`
  margin-top: 1rem;
  margin-bottom: 2rem;

  @media (min-width: 992px) {
    display: none;
  }
`;

export default function CourseInfo(props: { courseId: string }) {
  let { courseId } = props;
  let firstArticleId = useAppSelector((state) => (state.article.ids.length > 0 ? state.article.ids[0].toString() : ""));

  let { title, shortDescripton, courseImage } = useAppSelector((state) => ({
    title: state.course.title,
    shortDescripton: state.course.shortDescription,
    courseImage: state.course.imageSrc,
  }));

  return (
    <Panel title="Мой курс">
      <CourseInfoContainer>
        <div style={{ position: "relative" }}>
          <StyledCourseImage id="course-image" src={courseImage} alt="" />
          <TitleAndDescription>
            <CourseTitle>{title}</CourseTitle>
            <CourseShortDescription>{shortDescripton}</CourseShortDescription>
            <StatsComponentContainerInImage>
              <CourseStatsComponent courseId={courseId} fontColor="white" />
            </StatsComponentContainerInImage>
          </TitleAndDescription>
        </div>

        <NavigationContainer>
          <StatsComponentContainerOutOfImage>
            <CourseStatsComponent courseId={courseId} fontColor="black" />
          </StatsComponentContainerOutOfImage>
          <div className="d-flex justify-content-center">
            <StartLearningButton to={`/course/${courseId}/${firstArticleId}`}>Начать учиться!</StartLearningButton>
          </div>

          <h1 style={{ marginTop: "4rem", fontSize: "2rem" }}>Структура курса</h1>
          <CourseNavigation afterClickOnLink={() => {}} />
        </NavigationContainer>
      </CourseInfoContainer>
    </Panel>
  );
}
