import Modal from "react-modal";

import QuizNavigation from "./QuizNavigation";
import QuestionView from "./Question";

import "./styles/modal.scss";

import { NeonModalDialog } from "../Common/NeonModal";
import QuizResult from "./QuizResult";
import { useAppSelector } from "../../app/hooks";
import { selectIsQuizComplete } from "../../features/quiz/quiz-slice";

Modal.setAppElement("#root");

type QuizModalProps = {
  modalIsOpen: boolean;
  closeModal: () => void;
  quizTitle: string;
};

export interface QuizCompletionProps {
  isQuizComplete: boolean;
}

export default function QuizModal(props: QuizModalProps) {
  let { modalIsOpen, closeModal, quizTitle } = props;
  let isComplete = useAppSelector(selectIsQuizComplete);
  let resultsDisplayed = useAppSelector((state) => state.quiz.resultsDisplayed);
  let quizId = useAppSelector((state) => state.quiz.currentId);  

  if (quizId === undefined) {
    return <></>;
  }

  return (
    <NeonModalDialog modalIsOpen={modalIsOpen} closeModal={closeModal} title={quizTitle}>
      <div className="modal-content">
        <QuizNavigation />
        {!resultsDisplayed && <QuestionView />}
        {isComplete && resultsDisplayed && <QuizResult quizId={quizId} closeQuizModal={closeModal} />}
      </div>
    </NeonModalDialog>
  );
}
