import { Link } from "react-router-dom";
import { useState } from "react";
import styled from "styled-components";
import { ThemeColorScheme, ThemeType } from "../../themes";
import { useAppDispatch, useAppSelector } from "../../app/hooks";

import { progressBlack, quizIcon, tableOfContents, courseIcon, getIcon } from "./Icons/icons";

import { activateAppArea } from "../../features/app/appSlice";
import NavigationModal from "../CourseNavigation/NavigationModal";

export const AppNavMenuList = styled.ul`
  margin: 0;
  margin-top: 1.5rem;
  padding: 0;

  @media (max-width: 768px) {
    justify-content: center;
    margin-top: 0;
    display: flex;
    flex-direction: row;
  }

  a {
    text-decoration: none !important;
  }

  a.active {
    text-decoration: none !important;
    font-weight: bold;
  }
`;

export const StyledNavItem = styled.li<ThemeType>`
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
  padding-left: 1.8rem;
  cursor: pointer;
  display: flex;
  list-style: none;
  border-radius: 1rem 0 0 1rem;
  margin-bottom: 0;
  color: ${(props: ThemeType) => props.theme.textColor};

  img {
    width: 20px;
    height: 20px;
    margin-right: 1.1rem;
  }

  span.large-screen {
    display: block;
  }

  span.small-screen {
    display: none;
  }

  @media (max-width: 768px) {
    padding: 0.25rem;
    margin-right: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    span.large-screen {
      display: none;
    }

    span.small-screen {
      display: block;
      font-size: 0.7rem;
      margin-top: 0.2rem;
      font-family: Arial, Helvetica, sans-serif;
    }

    img {
      margin-right: 0;
      width: 16px;
    }
  }
`;

export function AppNavMenu() {
  let dispatch = useAppDispatch();
  let activeArea = useAppSelector((state) => state.app.activeArea);
  let courseId = useAppSelector((state) => state.course.id);
  let colorScheme = useAppSelector((state => state.app.colorScheme)) as ThemeColorScheme;

  let [modalIsOpen, setModalIsOpen] = useState(false);

  function closeModal() {
    setModalIsOpen(false);
  }

  return (
    <>
      <NavigationModal closeModal={closeModal} modalIsOpen={modalIsOpen} />
      <AppNavMenuList>
        <Link
          to="/progress"
          onClick={() => {
            dispatch(activateAppArea("progress"));
          }}
          style={{ textDecoration: "none" }}
          className={activeArea === "progress" ? "active" : ""}
        >
          <StyledNavItem>
            <img alt="ok" src={getIcon(colorScheme, "Progress.svg")} />
            <span className="large-screen">Прогресс</span>
            <span className="small-screen">Прогресс</span>
          </StyledNavItem>
        </Link>
        <Link
          to={`/quiz/${courseId}`}
          onClick={() => {
            dispatch(activateAppArea("quiz"));
          }}
          style={{ textDecoration: "none" }}
          className={activeArea === "quiz" ? "active" : ""}
        >
          <StyledNavItem>
            <img alt="ok" src={getIcon(colorScheme, "Quiz.svg")} />
            <span className="large-screen">Проверка знаний</span>
            <span className="small-screen">Проверка</span>
          </StyledNavItem>
        </Link>
        <Link
          to={`/course/${courseId}`}
          onClick={() => {
            dispatch(activateAppArea("my-course"));
          }}
          style={{ textDecoration: "none" }}
          className={activeArea === "my-course" ? "active" : ""}
        >
          <StyledNavItem>
            <img alt="ok" src={getIcon(colorScheme, "Course.svg")} />
            <span className="large-screen">Мой курс</span>
            <span className="small-screen">Курс</span>
          </StyledNavItem>
        </Link>
        <StyledNavItem
          onClick={() => {
            setModalIsOpen(true);
          }}
          style={{ textDecoration: "none" }}
        >
          <img alt="ok" src={getIcon(colorScheme, "Table-of-contents.svg")} />
          <span className="large-screen">Разделы курса</span>
          <span className="small-screen">Разделы</span>
        </StyledNavItem>
      </AppNavMenuList>
    </>
  );
}
