import { ReactNode } from "react";
import styled from "styled-components";
import { ThemeType } from "../../themes";
import "./panel.scss";

type PanelProps = {
  children: ReactNode;
  title?: string;
  padding?: string;
};

const StyledPanel = styled.div<ThemeType>`
  background-color: ${(props: ThemeType) => props.theme.panel.bg};
  color: ${(props: ThemeType) => props.theme.panel.color};
  border-radius: 0rem;
  margin-bottom: 1rem;
  border: ${(props: ThemeType) => props.theme.panel.border};
  box-shadow: 0px 0px 5px 5px rgb(0, 0, 0, 0.01);
  border-radius: 1rem;

  @media(max-width: 768px) {
      border-radius: 0rem;
      box-shadow:none;
      border:none;
  }

  &:last-of-type {
      margin-bottom: 0rem;
  }

  .panel-title {
      font-weight: bold;
      font-size: 1.1rem;
      margin-bottom: 2rem;
  }
`;

export default function Panel(props: PanelProps) {
  let { title, children, padding } = props;

  return (
    <StyledPanel style={{ padding: padding ?? "1rem" }}>
      {title !== undefined && (
        <div className="panel-title">
          <span>{title}</span>
        </div>
      )}
      <div className="panel-content">{children}</div>
    </StyledPanel>
  );
}
