import { Link } from "react-router-dom";
import styled from "styled-components";
import { useAppSelector } from "../../app/hooks";
import { ThemeType } from "../../themes";

type NextArticleLinkProps = {
  currentArticleId: string;
  onClick?: () => void;
};

const ArticleSpan = styled.span<ThemeType>`
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  font-size: 1.5rem;
`;

const SlideContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    margin-bottom: 0.5rem;
  }
`;

export default function NextArticleLink(props: NextArticleLinkProps) {
  let { currentArticleId, onClick } = props;

  let courseId = useAppSelector((state) => state.course.id);
  let currentArticleTitle = useAppSelector((state) => state.article.entities[currentArticleId]?.title);

  let nextArticleId = useAppSelector((state) => {
    if (currentArticleId === undefined) {
      return undefined;
    }
    let all = state.article.ids.map((id) => id.toString());
    let indexOfCurrent = all.indexOf(currentArticleId);
    if (indexOfCurrent === -1) {
      throw new Error(`Current article id is invalid: <${currentArticleId}>`);
    }
    if (indexOfCurrent <= all.length - 2) {
      return state.article.ids[indexOfCurrent + 1];
    } else {
      return undefined;
    }
  });

  let nextArticleTitle = useAppSelector((state) =>
    nextArticleId !== undefined ? state.article.entities[nextArticleId]?.title : ""
  );

  function ScrollToStart() {
    if (onClick !== undefined) {
      onClick();
    }
    document.getElementById("app-workspace")?.scrollTo(0, 0);
  }

  return nextArticleId !== undefined ? (
    <SlideContainer>
      <p>Перейти к статье</p>
      <Link
        style={{ fontSize: "1.5rem", textAlign: "center" }}
        to={`/course/${courseId}/${nextArticleId}`}
        onClick={ScrollToStart}
        className="next-article-button"
      >
        {nextArticleTitle}
      </Link>
    </SlideContainer>
  ) : (
    <></>
  );
}
