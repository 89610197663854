import styled from "styled-components";
import { useAppSelector } from "../../app/hooks";
import { getColorSchemeObject, ThemeType } from "../../themes";
import logoBlack from "./Logo black.svg";
import logoWhite from "./Logo white.svg";

const LogoContainer = styled.div`
  margin-top: 1.5rem;
  margin-left: 1.5rem;
  margin-bottom: 2rem;

  @media (max-width: 768px) {
    display: none;
  }
`;

export default function Logo() {
  let theme = useAppSelector((state) => getColorSchemeObject(state.app.colorScheme));

  return (
    <LogoContainer className="logo-container">
      {theme.colorScheme === 'light' && <img src={logoBlack} height="32px" />}
      {theme.colorScheme === 'dark' && <img src={logoWhite} height="32px" />}
    </LogoContainer>
  );
}
