import styled from "styled-components";
import { PieChart } from "react-minimal-pie-chart";
import { useAppSelector } from "../../app/hooks";
import { getColorSchemeObject } from "../../themes";

type ResultCardProps = {
  indicator: string;
  indicatorValue: string;
  total: number;
  value: number;
};

const StyledResultCard = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid rgb(230, 230, 230);
  border-radius: 0.5rem;
  padding: 1rem;

  @media (max-width: 768px) {
    margin-bottom: 1rem;
  }
`;

const Indicator = styled.p`
  color: rgb(100, 100, 100);
  font-size: 1rem;
  margin: 0;
`;

const IndicatorValue = styled.p`
  font-size: 2.5rem;
  font-weight: bold;
  margin: 0;
`;

const Stats = styled.div`
  margin-right: auto;
`;

export default function ResultCard(props: ResultCardProps) {
  let { indicator, indicatorValue, total, value } = props;
  let colorScheme = useAppSelector((state) => {
    let scheme = state.app.colorScheme;
    return getColorSchemeObject(scheme);
  });

  return (
    <StyledResultCard className="result-card">
      <Stats>
        <Indicator>{indicator}</Indicator>
        <IndicatorValue>{indicatorValue}</IndicatorValue>
      </Stats>
      <PieChart
        style={{ width: "120px" }}
        viewBoxSize={[20, 20]}
        center={[10, 10]}
        radius={10}
        data={[
          { title: "Пройдено", value: value, color: colorScheme.quizProgressChart.progress },
          { title: "Осталось пройти", value: total - value, color: colorScheme.quizProgressChart.background },
        ]}
        lineWidth={25}
      />
    </StyledResultCard>
  );
}
