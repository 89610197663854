import { useAppSelector } from "../../app/hooks";
import { selectIsCorrectlyAnswered, selectIsQuestionChecked, selectQuestion } from "../../features/quiz/quiz-slice";

export default function AnswerResult() {
  let isChecked = useAppSelector(selectIsQuestionChecked);
  let isCorrect = useAppSelector(selectIsCorrectlyAnswered);
  let correctOptions = useAppSelector((state) => {
    let currQuestion = selectQuestion(state);
    return currQuestion.options.filter((opt) => opt.isCorrect);
  });
  if (isChecked) {
    if (isCorrect === true) {
      return (
        <div className="alert alert-success m-0">
          <span>Правильно!</span>
        </div>
      );
    } else {
      return (
        <div className="alert alert-danger m-0">
          <span>
            Ответ неправильный. Правильный вариант:
            {correctOptions.map((opt, i) => (
              <span key={i} style={{ fontWeight: "bold", marginLeft: "0.5rem" }}>
                {opt.text}
              </span>
            ))}
          </span>
        </div>
      );
    }
  } else {
    return <></>;
  }
}
