import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import ArticleProgressTable from "../../Components/ArticleProgressTable";
import Panel from "../../Components/Common/Panel";
import ResultCard from "../../Components/ResultCard";
import {
  reset,
  selectCompletedQuizesStats,
  selectCorrectQuestionsStats,
  selectQuestionCompletionStats,
} from "../../features/quiz/quiz-slice";


const StatsRow = styled.div`
  margin-bottom: 3rem;
`;

const StyledResetProgressButton = styled.button`
  background-color: rgb(242, 18, 48);
  color: white;
  border: none;
  border-radius: 0.5rem;
  padding: 0.5rem 1.2rem;
`;

export default function Progress() {
  let dispatch = useAppDispatch();
  let { totalQuizes, completedQuizes } = useAppSelector(selectCompletedQuizesStats);
  let { totalQuestions, completedQuestions } = useAppSelector(selectQuestionCompletionStats);
  let correctnessStats = useAppSelector(selectCorrectQuestionsStats);

  return (
    <div className="container-fluid" style={{ padding: 0 }}>
      <Panel title="Статистика">
        <StatsRow className="row">
          <div className="col-12 col-md-4">
            <ResultCard
              indicator="Пройдено тестов"
              indicatorValue={`${completedQuizes}/${totalQuizes}`}
              total={totalQuizes}
              value={completedQuizes}
            />
          </div>
          <div className="col-12 col-md-4">
            <ResultCard
              indicator="Пройдено вопросов"
              indicatorValue={`${completedQuestions}/${totalQuestions}`}
              total={totalQuestions}
              value={completedQuestions}
            />
          </div>
          <div className="col-12 col-md-4">
            <ResultCard
              indicator="Правильных ответов"
              indicatorValue={`${correctnessStats.correctlyAnswered}/${totalQuestions}`}
              total={totalQuestions}
              value={correctnessStats.correctlyAnswered}
            />
          </div>
        </StatsRow>
      </Panel>
      <Panel title="Мой прогресс">
        <ArticleProgressTable />
      </Panel>
      <Panel title="Дополнительно">
        <div className="row">
          <div className="col-12">
            <StyledResetProgressButton
              onClick={() => {
                dispatch(reset());
              }}
            >
              Начать заново
            </StyledResetProgressButton>
          </div>
        </div>
      </Panel>
    </div>
  );
}
