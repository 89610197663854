import CourseNavigation from "./CourseNavigation";

import { NeonModalDialog, NeonModalProps } from "../Common/NeonModal";

export default function NavigationModal(props: NeonModalProps) {
  let { modalIsOpen, closeModal } = props;

  function ScrollToStart() {
    document.getElementById("app-workspace")?.scrollTo(0, 0);
  }

  return (
    <NeonModalDialog
      title="Структура курса"
      modalIsOpen={modalIsOpen}
      closeModal={closeModal}
    >
      <CourseNavigation
        afterClickOnLink={() => {
          ScrollToStart();
          closeModal();
        }}
      />
    </NeonModalDialog>
  );
}
