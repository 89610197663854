//libs
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

//components
import SlideContentPresenter, { GoNextArticleSlide } from "./SlideContentPresenter";
import { Article, ArticleTag } from "../../features/datacontext/dbcontext";

type SwiperSlidesProps = {
  article?: Article;
  currentArticleId: string;
};

export default function SwiperSlides(props: SwiperSlidesProps) {
  //let tags = props.article?.tags ?? [];
  let { article, currentArticleId } = props;

  function divideByHeaders(tags: ArticleTag[]): Array<Array<ArticleTag>> {
    let result = [];
    let currentTags = [] as ArticleTag[];

    for (let i = 0; i < tags.length; i++) {
      let currentTag = tags[i];
      if (currentTag.tagName === "h" && currentTags.length > 0) {
        result.push(currentTags);
        currentTags = [currentTag];
      } else {
        currentTags.push(currentTag);
      }
    }
    if (currentTags.length > 0) {
      result.push(currentTags);
    }
    return result;
  }

  let slidesContent = divideByHeaders(article?.tags ?? []);

  let contentSlides = slidesContent.map((slide, i) => (
    <SwiperSlide key={`slide_${i}`}>
      <SlideContentPresenter
        articleId={currentArticleId}
        slideIndex={i}
        totalSlides={slidesContent.length}
        content={slide}
      />
    </SwiperSlide>
  ));
  contentSlides.push(
    <SwiperSlide key="next-article">
      <GoNextArticleSlide currentArticleId={currentArticleId} />
    </SwiperSlide>
  );

  return (
    <Swiper spaceBetween={1} slidesPerView={1} initialSlide={0} autoHeight={true}>
      {contentSlides}
    </Swiper>
  );
}
