import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { selectArticlesWithUncheckedQuizes, selectNextArticle } from "../../features/course/articleSlice";
import { Article } from "../../features/datacontext/dbcontext";
import {
  selectNumberOfUncheckedQuizes,
  selectQuizCompletionStats,
  selectQuizWithId,
} from "../../features/quiz/quiz-slice";
import { ThemeType } from "../../themes";

type QuizResultProps = {
  quizId: string;
  closeQuizModal: () => void;
};

const ResultContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledCloseButton = styled.a`
  background: #333333;
  color: white;
  border: none;
  cursor: pointer;

  margin-right: 1rem;
  margin-top: 0.5rem;
  border-radius: 0.5rem;
  text-decoration: none;
  padding: 0.5rem;

  &:hover {
    text-decoration: underline;
    color: white;
  }
`;

const LinkToArticle = styled(Link)`
  background: #333333;
  color: white;
  border: none;
  cursor: pointer;

  margin-right: 1rem;
  margin-top: 0.5rem;
  border-radius: 0.5rem;
  text-decoration: none;
  padding: 0.5rem;

  &:hover {
    text-decoration: underline;
    color: white;
  }
`;

function GoToArticleButton(props: { articleId: string; articleTitle: string; closeQuizModal: () => void }) {
  let { articleId, articleTitle, closeQuizModal } = props;

  let courseId = useAppSelector((state: RootState) => state.course.id);

  return (
    <LinkToArticle
      to={`/course/${courseId}/${articleId}`}
      onClick={() => {
        closeQuizModal();
      }}
    >
      {articleTitle}
    </LinkToArticle>
  );
}

const StyledResultMessage = styled.p<ThemeType>`
  text-align: center;
  margin-top: 1rem;
  font-size: 1.5rem;
  color: ${(props: ThemeType) => props.theme.highlightedTextColor};
`;

const StyledNumericResultSpan = styled.span`
  padding: 0.5rem;
  border-radius: 0.5rem;
  background-color: #8cfff9;
  color: black;
  fontweight: bold;
`;

export default function QuizResult(props: QuizResultProps) {
  let { quizId, closeQuizModal } = props;

  let quiz = useAppSelector((state) => selectQuizWithId(state, quizId));
  let stats = useAppSelector((state) => selectQuizCompletionStats(state, quizId ?? ""));

  let otherArticles = useAppSelector((state) => Object.values(state.article.entities)) as Article[];
  let uncheckedQuizesCount = useAppSelector((state) => selectNumberOfUncheckedQuizes(state, quizId));
  let navigate = useNavigate();

  return (
    <ResultContainer>
      <div style={{ width: "80%", marginTop: "2rem", textAlign: "center" }}>
        <p>Пройден тест</p>
        <h1 style={{ marginTop: "1rem" }}>{quiz.title}</h1>
        <StyledResultMessage>
          Вы ответили правильно на{" "}
          <StyledNumericResultSpan>
            {stats.correctlyAnswered} из {stats.totalQuestions}
          </StyledNumericResultSpan>
        </StyledResultMessage>
        {uncheckedQuizesCount === 0 && <p>Статья пройдена!</p>}
        {uncheckedQuizesCount > 0 && <p>Осталось еще {uncheckedQuizesCount} теста в статье</p>}
        <div style={{ marginTop: "2rem" }} className="d-flex justify-content-center align-items-center flex-wrap">
          <span style={{ marginRight: "1rem" }}>Перейти к статьям</span>
          {otherArticles.map((art) => (
            <GoToArticleButton
              key={art.id}
              articleId={art.id}
              articleTitle={art.title}
              closeQuizModal={closeQuizModal}
            />
          ))}
          <span style={{ marginRight: "1rem" }}>или</span>
          <StyledCloseButton
            onClick={() => {
              navigate(-1);
            }}
          >
            Закрыть тест
          </StyledCloseButton>
        </div>
      </div>
    </ResultContainer>
  );
}
