import { Link } from "react-router-dom";
import styled from "styled-components";
import { useAppSelector } from "../../app/hooks";
import { Article } from "../../features/datacontext/dbcontext";
import { quizesForArticle } from "../../features/quiz/quiz-slice";
import { ThemeType } from "../../themes";

import "./article-card.scss";

type ArticleCardProps = {
  article: Article;
  courseId: string;
  afterClickOnLink: () => void;
};

const ArticleTitle = styled.p<ThemeType>`
  font-size: 1.1rem;
  font-weight: bold;
  margin: 0;
  margin-top: 1rem;
  margin-bottom: 0.2rem;
  color: ${(props: ThemeType) => props.theme.courseStructure.articleHeaderColor};

  @media (min-width: 786px) {
    margin-top: 0rem;
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
  }
`;

const ShortDescription = styled.p<ThemeType>`
  font-size: 1rem;
  font-weight: 300;
  margin: 0;
  color: ${(props: ThemeType) => props.theme.courseStructure.shortDescriptionColor};

  @media (min-width: 786px) {
    font-size: 1rem;
  }
`;

const CountOfQuizes = styled.p`
  margin-top: 0.5rem;
  font-size: 0.8rem;
  color: ${(props: ThemeType) => props.theme.courseStructure.addInfoColor};

  @media (min-width: 786px) {
    margin-top: 0.5rem;
    color: rgb(150, 150, 150);
  }
`;

const Image = styled.img<ThemeType>`
  width: 100%;
  height: auto;
  border-radius: 0.5rem;
  box-shadow: ${(props: ThemeType) => props.theme.articleImageShadow};

  @media (min-width: 786px) {
    width: 200px;
    height: auto;
    border-radius: 0.5rem;
    margin-right: 1rem;
  }
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 786px) {
    display: flex;
    flex-direction: column;
  }
`;

const StyledArticleCard = styled.div`
  margin-bottom: 1rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid rgb(200, 200, 200);
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (min-width: 786px) {
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid rgb(200, 200, 200);
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
`;

export default function ArticleCard(props: ArticleCardProps) {
  let { courseId, article, afterClickOnLink } = props;
  let linkTo = `/course/${courseId}/${article.id}`;

  let quizesCount = useAppSelector((state) => quizesForArticle(state, article.id)).length;

  let test = quizesCount === 1 ? "тест" : quizesCount >= 2 && quizesCount <= 4 ? "теста" : "тестов";

  return (
    <Link to={linkTo} style={{ textDecoration: "none", color: "black" }} onClick={afterClickOnLink}>
      <StyledArticleCard>
        <Image src={article.imageSrc} alt="" className="img-fluid" />
        <Details>
          <ArticleTitle>{article.title}</ArticleTitle>
          <ShortDescription>{article.shortDescription}</ShortDescription>
          {quizesCount > 0 && (
            <CountOfQuizes>
              {quizesCount} {test} для проверки знаний
            </CountOfQuizes>
          )}
        </Details>
      </StyledArticleCard>
    </Link>
  );
}
