import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import QuizModal from "../../Components/QuizModal/QuizModal";
import Spinner from "../../Components/Spinner/Spinner";
import { initApp } from "../../features/app/app-initializer";
import { selectQuizWithId, startQuiz } from "../../features/quiz/quiz-slice";

export default function QuizPage() {
  let dispatch = useAppDispatch();
  let params = useParams();
  let navigate = useNavigate();
  let courseId = params.courseId !== undefined ? params.courseId : "";
  let quizId = params.quizId !== undefined ? params.quizId : "";

  let isLoadingInitial = useAppSelector((state) => state.app.isLoadInitial);
  let isLoading = useAppSelector((state) => state.app.status === "loading");
  let courseIsFetched = useAppSelector((state: RootState) => state.article.ids.length > 0);
  let quiz = useAppSelector((state: RootState) => {
    return courseIsFetched ? selectQuizWithId(state, quizId) : undefined;
  });

  useEffect(() => {
    initApp(dispatch, courseId, isLoadingInitial);
    if (quiz !== undefined) {
      dispatch(startQuiz(quiz.id));
    }
  }, [dispatch, quizId, courseId, isLoadingInitial, courseIsFetched]);

  function closeModal() {
    navigate(-1);
  }

  if (isLoadingInitial) {
    return <>{isLoading && <Spinner />}</>;
  }

  return <QuizModal modalIsOpen={true} closeModal={closeModal} quizTitle={quiz?.title ?? ""} />;
}
