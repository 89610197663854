import { Theme } from ".";

export const neonTheme: Theme = {
  colorScheme: "dark",
  appBg: "#252525",
  textColor: "white",
  highlightedTextColor: "rgb(19 205 130)",
  button: {
    bg: "#98ff99",
    color: "black",
    hover: {
      bg: "#c3ffc4",
      color: "black",
    },
    disabled: {
      bg: "#c3ffc4",
      color: "black",
    },
  },
  modal: {
    bg: "#252525",
    color: "white",
    overlayBg: "rgba(0, 0, 0, 0.8)",
    shadow: "10px -10px 13px 0px rgb(22 255 161)",
    borderStyle: "2px solid rgb(22,255,161)",
    closeButtonColor: "white",
  },
  answerOption: {
    hoverBackground: "rgb(46, 46, 46)",
  },
  quizNavBtn: {
    bg: "#535353",
    color: "white",
    active: {
      bg: "#f1ffa8",
      color: "black",
    },
  },
  correctnessInd: {
    correct: "#72e174",
    incorrect: "#ff8c8c",
  },
  correctOptionIndicatorColor: "#5fd38d",
  incorrectOptionIndicatorColor: "#ff8e99",
  articleImageShadow: "0px 0px 11px 4px #404040",
  courseStructure: {
    articleHeaderColor: "#9afcff",
    shortDescriptionColor: "white",
    addInfoColor: "white",
  },
  panel: {
    bg: "#2f2e2e",
    color: "white",
    border: "1px solid #303030",
  },
  quizCard: {
    border: "1px solid rgb(255 255 255 / 8%)",
    shadow: "1px -1px 8px 5px rgb(255 255 255 / 5%)",
  },
  quizProgressChart: {
    background: "white",
    progress: "rgb(242, 18, 48)",
  },
  quizResultRowHoverBg: "#404040",
};
